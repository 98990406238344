import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Fuse from "fuse.js";
import { useWebSocket } from '../../components/useWebSocket';
import { Box, Typography, Button, Grid, TextField, InputAdornment, Popover, Alert, Chip, Dialog, DialogContentText, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs'; // Import dayjs and Dayjs type
import { JobDetails3, JobData2 } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalReportNumber, setCurrentReportPageNumber, setTotalRobotJobsPageNumber, setTotalReportPerPage  } from "../../store/page/jobPageSlice";
import { formatDate } from '../../utils/utils';
import axios from 'axios';
import ReportTable from './components/ReportTable';
interface columnType {
    id: string;
    label: string;
    padding?: string;
}
interface ReportState {
    id: number;
    requester: string;
    generating_time: number;
    completed_time: number;
    start_time: number;
    end_time: number;
    status: string;
    report_csv: string;
    tags: string;
    report_type: string;
}
const ReportHistory = () => {
    const dispatch = useDispatch();

    const reportColumn: columnType[] = [
        { id: 'id', label: 'Report ID' },
        { id: 'requester', label: 'Requester',  },
        { id: 'report_type', label: 'Report Type' },
        { id: 'generating_time', label: 'Created Time', padding: "15px 20px" },
        { id: 'start_time', label: 'Start Date', padding: "15px 20px" },
        { id: 'end_time', label: 'End Date'  , padding: "15px 20px"},
        { id: 'tags', label: 'Filter Tags' },
        { id: 'status', label: 'Status' },
        { id: 'report_csv', label: 'Download' },
    ];

    const [reportData, setReportData] = useState<ReportState[]>([]);

    const getReportData = async (pageNumber: number, rowsPerPage: number) => {
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/report/?page=${pageNumber}&page_size=${rowsPerPage}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                setReportData(response.data.data);
                dispatch (setTotalReportNumber(response.data.count));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }
    const pageSize = useSelector((state: any) => state.jobPage.totalReportPerPage);
    const pageNumber = useSelector((state: any) => state.jobPage.currentReportPageNumber);

    useEffect(() => {
        getReportData(pageNumber, pageSize);
    }, []);

    return ( 
        <Box sx={{ maxWidth: "95%", m: "15px auto" }}>
            <Typography variant="h5" sx={{ mb: 2, color:"black", fontSize: "24px", fontWeight:"600" }}>Report History</Typography>
            <ReportTable
                items={reportData}
                columns={reportColumn}
                getReportData={getReportData}
            />
        </Box>
    );
}
 
export default ReportHistory;