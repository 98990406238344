import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { smoothTransition } from "../../utils/animate"
import { shadow } from "../../utils/styles"
import './index.css'
// import { cn } from "@/lib/utils"

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={"peer switch-parent"}
    style={{
      display: 'inline-flex',
      height: '1.5rem',
      width: '2.75rem',
      flexShrink: 0,
      cursor: 'pointer',
      alignItems: 'center',
      borderRadius: '9999px',
      ...smoothTransition,
    }}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={"pointer-events-none switch-thumb ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"}
      style={{ 
        display: 'block',
        height: '1.25rem',
        width: '1.25rem',
        borderRadius: '9999px',
        backgroundColor: 'white',
        ...shadow,
        ...smoothTransition,
      }}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
