import React, { useState, useEffect } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, IconButton, Collapse, Box, CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getStatusColor } from "../../../utils/ColourStatus";
import { JobData2, JobDetails3 } from "../../../store/types";
import InfiniteScroll from 'react-infinite-scroll-component';

interface JobTableProps {
  actions?: string[];
  type?: string;
  columns: Column[];
  items: JobData2;
  getJobList: (pageNumber: number, pageSize: number) => void;
  page: number;
    rowsPerPage: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Column {
  id: string;
  label: string;
  padding?: string;
}

interface ProcessedJobDetails {
  id: string;
  order_number: string;
  job_type: string;
  robot_name: string;
  robot_id: string;
  load_compartment: string;
  sender_location: string;
  recipient_location: string;
  duration: string;
  status: string;
  created_at: number;
  timestamp: number;
  details: detailed[];
}

interface detailed {
  order_number: string;
  event: {
    robot_mac: string;
    event_type: string;
    created_time: number;
    remarks: string;
  };
  from_location: string;
  to_location: string;
  start_time: number;
  completed_time: number;
}

const JobTable: React.FC<JobTableProps> = ({ columns, items, getJobList, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set()); // Track multiple expanded rows
    const [jobEvents, setJobEvents] = useState<{ [key: string]: detailed[] }>({}); // Store events per job
    const totalJobs = useSelector((state: any) => state.jobPage.totalJobNumber);
    const dispatch = useDispatch();
    const [pageDetailNumber, setDetailPageNumber] = useState<{ [key: string]: number }>({});
    const [hasMoreDetails, setHasMoreDetails] = useState<{ [key: string]: boolean }>({});

    const handleExpandClick = (jobId: string) => {
        const updatedExpandedRows = new Set(expandedRows);
        if (updatedExpandedRows.has(jobId)) {
            updatedExpandedRows.delete(jobId); // Collapse if already expanded
        } else {
            updatedExpandedRows.add(jobId); // Expand new row
            fetchJobDetails(1, 10, jobId.toString(), true); // Fetch job details for this job, reset the details
        }
        setExpandedRows(updatedExpandedRows);
    };
  
    const defaultHeaderStyle = {
      fontSize: "14px",
      color: "grey",
      padding: "15px 50px", 
      fontWeight: "bold",
      backgroundColor: "#F5F9FF",
      whiteSpace: "nowrap"
    };
  
    // Render function for the header
    const renderHeader = (column: Column) => {
      return {
        ...defaultHeaderStyle,
        padding: column.padding ? column.padding : defaultHeaderStyle.padding
      };
    };
  
    const fetchJobDetails = async (pageNumber: number, pageSize: number, orderNumber: string, reset = false) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_details/?order_number=${orderNumber}&page=${pageNumber}&page_size=${pageSize}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          );
          if (response.status === 200) {
            const fetchedData = response.data.data;
            setJobEvents(prev => ({
              ...prev,
              [orderNumber]: reset ? fetchedData : [...(prev[orderNumber] || []), ...fetchedData]
            }));
            setDetailPageNumber(prev => ({ ...prev, [orderNumber]: pageNumber }));
            setHasMoreDetails(prev => ({
              ...prev,
              [orderNumber]: pageNumber < response.data.num_pages
            }));
          }
        } catch (error) {
          console.error('Error fetching job details:', error);
        }
    };

    const loadMoreDetails = (orderNumber: string) => {
        const nextPage = (pageDetailNumber[orderNumber] || 1) + 1;
        fetchJobDetails(nextPage, 10, orderNumber);
    };
  
    // const handleChangePage = (event: unknown, newPage: number) => {
    //   setPage(newPage);
    //   getJobList(newPage + 1, rowsPerPage);
    // };
  
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   const pageSize = parseInt(event.target.value, 10);
    //   setRowsPerPage(pageSize);
    //   setPage(0);
    //   getJobList(1, pageSize);
    // };
  
    const formatDate = (timestamp: number) => {
      const date = new Date(timestamp * 1000);
      const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
      const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const day = date.toLocaleDateString('en-GB', dayOptions);
      const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
      const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true });
  
      return (
        <>
          <Typography variant="body2" component="span" sx={{ fontSize: "12px" }}>{time}</Typography>
          <br />
          <Typography variant="body2" component="span" sx={{ fontSize: "12px" }}>{day}, {formattedDate}</Typography>
        </>
      );
    };
  
    const renderRow = (info: ProcessedJobDetails) => {
        const isExpanded = expandedRows.has(info.id);
        return (
            <React.Fragment key={info.id}>
            <TableRow
                sx={{ cursor: 'pointer', backgroundColor: "white", '&:hover': { backgroundColor: '#f7f7f7' } }}
                onClick={() => handleExpandClick(info.id)}
            >
                <TableCell padding="checkbox">
                <IconButton size="small" onClick={() => handleExpandClick(info.id)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                </TableCell>
                {columns.map(column => {
                const value = info[column.id as keyof ProcessedJobDetails];
                return (
                    <TableCell
                    key={column.id}
                    align="center"
                    sx={{
                        fontSize: "12px",
                        color: column.id === 'status' ? getStatusColor(info.status).toString() : 'black',
                    }}>
                    {column.id === 'created_at'
                        ? formatDate(info.created_at)
                        : column.id === 'timestamp' && info.timestamp === -1
                        ? ""
                        : column.id === 'timestamp'
                            ? formatDate(info.timestamp)
                            : column.id === 'duration' && info.duration === "-1"
                            ? ""
                            : column.id === 'status'
                                ? value.toString().split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())
                                : Array.isArray(value) ? JSON.stringify(value) : value}
                    </TableCell>
                );
                })}
            </TableRow>
            {isExpanded && (
                <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1} sx={{ backgroundColor: "#f7f7f7", }}>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Box margin={1} sx={{ width: "60%" }} id={`scrollable-${info.order_number}`} style={{ overflowY: "auto", maxHeight: "300px" }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Event History
                        </Typography>
                        {jobEvents[info.order_number] && jobEvents[info.order_number].length > 0 ? (
                            <InfiniteScroll
                                dataLength={jobEvents[info.order_number]?.length || 0}
                                next={() => loadMoreDetails(info.order_number)}
                                hasMore={hasMoreDetails[info.order_number] || false}
                                loader={
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <CircularProgress />
                                    </Box>
                                }
                                scrollableTarget={`scrollable-${info.order_number}`}
                                endMessage={<Typography align="center" sx={{ fontSize: "12px", color:"#757575", fontStyle:"italic", mt:1, mb:1 }}>No more events to show</Typography>}
                            >
                                <Table size="small" aria-label="events">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Event Type</TableCell>
                                            <TableCell align="center">From</TableCell>
                                            <TableCell align="center">To</TableCell>
                                            <TableCell align="center">Remarks</TableCell>
                                            <TableCell align="center">Start Time</TableCell>
                                            <TableCell align="center">End Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {jobEvents[info.order_number].map((event, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {event.event.event_type.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())}
                                                </TableCell>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {event.from_location}
                                                </TableCell>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {event.to_location}
                                                </TableCell>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {event.event.remarks}
                                                </TableCell>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {formatDate(event.start_time)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                                    {event.completed_time === -1 ? "" : formatDate(event.completed_time)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </InfiniteScroll>
                        ) : (
                            <Typography variant="body2">No events found</Typography>
                        )}
                    </Box>
                </Collapse>
                </TableCell>
                </TableRow>
            )}
        </React.Fragment>
      );
    };
  
    // Map items to match ProcessedJobDetails
    const processedItems: ProcessedJobDetails[] = Object.values(items).map((job: JobDetails3) => ({
      id: job.job_id.toString(),
      order_number: job.job_id.toString(),
      job_type: job.job_type,
      robot_name: job.robot_name || "",
      robot_id: job.robot_id,
      load_compartment: job.compartment?.toString() || "N/A",
      sender_location: job.from_location,
      recipient_location: job.to_location,
      duration: job.duration.toString(),
      status: job.status,
      created_at: job.created_time,
      timestamp: job.completed_time,
      details: [], // Make sure to have details initialized if not already
    }));
  
    return (
      <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', height: "620px", borderRadius: "10px", border: "1px solid #E0E0E0", m: '20px auto' }}>
        <TableContainer sx={{ backgroundColor: "#F5F5F5", alignItems: "center", overflowX: "auto" }}>
          <Table stickyHeader>
            <TableHead sx={{ width: "100%", margin: "auto" }}>
              <TableRow>
              <TableCell sx={{ backgroundColor: "#F5F9FF", width: "50px", borderRadius: "10px",}} />
                {columns.map(column => (
                  <TableCell key={column.id} align="center" sx={{ ...renderHeader(column) }}>
                    {column.label}
                  </TableCell>
                ))}

              </TableRow>
            </TableHead>
            <TableBody>
              {processedItems.map(info => renderRow(info))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={totalJobs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ flexShrink: 0, boxShadow: "none" }}
        />
      </Paper>
    );
  };
  
  export default JobTable;
