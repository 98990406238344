import axios from "axios";
import { API_URL } from "../../utils/const";
import { getToken } from "../../utils/api";

const ENDPOINT_JOB_DETAIL = "v1/rms/job_logs"
const token = getToken();
export const getJobLogs = async(order_number: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}${ENDPOINT_JOB_DETAIL}/?order_number=${order_number}`,
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    });

    return response.data || response
  } catch (error) {
    console.error(error);
  }
}