import { start } from "repl";
import flashbotCrop from "../assets/flashbotCrop.png";

const robotDetails = {
    name: "Flashbot",
    macAddr: "60:FB:00:91:2B:CC",
    status: "Delivering",
    batteryLevel: "80.00%",
    connectivity: "Online",
    map: "https://www.google.com/maps",
    location: "Charging Dock",
}

const listOfJobs = [
    {   
        orderNo: "#D05ABED7",
        timeStamp: "19-10-2023 20:38",
        status: "Dispatching",
        pickUp: "#02-07",
        dropOff: "#01-23",
        estimatedTime: "2 mins",
        currentLocation: "#01-07",
        robotName: "Flashbot",
        robotMacAddr: "60:FB:00:91:2B:CC",
    },
    {
        orderNo: "#D05ABE37",
        timeStamp: "19-10-2023 20:38",
        status: "Robot Picking Up",
        pickUp: "#01-08",
        dropOff: "#02-07",
        estimatedTime: "10 mins",
        currentLocation: "#01-07",
        robotName: "Flashbot",
        robotMacAddr: "60:FB:00:91:2B:CC",
    },
    {
        orderNo: "#D05ABE36",
        timeStamp: "19-10-2023 20:38",
        status: "Completed",
        pickUp: "#02-07",
        dropOff: "#01-23",
        estimatedTime: "0 mins",
        currentLocation: "#01-23",
        robotName: "Flashbot",
        robotMacAddr: "60:FB:00:91:2B:CC",
    },
];

const cardDetails = [
    { title: "Total Robots", data: "2" },
    { title: "Idle", data: "1" },
    { title: "Busy", data: "1" },
    { title: "Offline", data: "0" },
];

const listOfAvailableRobots = [
    {
        name: "Call Nearest Robot",
        description: "Priority call the fastest responding robot",
        isRobot: false
    },
    {
        name: "Flashbot",
        serialNo: "KRW32208L10006",
        location: "Charging Dock",
        status: "Idle",
        imageUrl: flashbotCrop, 
        isRobot: true
    },
];

const mapDetails = {
    mapName: "One North",
    locations: [
        {
            name: "C10",
            pose: {x: 30, y: 30, z: 1},
            GIS: [103.78651301499536, 1.2973771141993922] as [number, number]
        },
        {
            name: "Table",
            pose: {x: 30, y: 70, z: 1},
            GIS: [103.78667353398225, 1.2973827781229375] as [number, number]
        },
        {
            name: "#01-23",
            pose: {x: 80, y: 50, z: 1},
            GIS: [103.78668297627502, 1.2972997072418764] as [number, number]
        },
        {
            name: "#02-07",
            pose: {x: 50, y: 10, z: 2},
            GIS: [103.78683688565661, 1.2973110350893506] as [number, number]
        },
        // {
        //     name: "#02-08",
        //     pose: {x: 0.5, y: 0.5, z: 2},
        // },
        // {
        //     name: "#02-23",
        //     pose: {x: 0.5, y: 0.5, z: 2},
        // },
    ],
}

const listOfRobots = [
    {
        name: "Flashbot",
        serialNo: "KRW32208L10006",
        macAddr: "60:FB:00:91:2B:CC",
        location: "Charging Dock",
        status: "Idle",
        batteryLevel: "90.00%",
        connectivity: "Online",
        map: "Autove",
        job: "None",
        startLat: 1.29743241508016,
        startLng: 103.78648122682,
        jobHistory: listOfJobs,
    },
    // {
    //     name: "Flashbot",
    //     serialNo: "KRW32208L10006",
    //     macAddr: "60:FB:00:91:2B:CC",
    //     location: "#01-08",
    //     status: "Delivering",
    //     batteryLevel: "80.00%",
    //     connectivity: "Online",
    //     map: "Autove",
    //     job: "#D05ABED7",
    //     startLat: 1.297465301082625,
    //     startLng: 103.78651496476421,
    //     jobHistory: listOfJobs,
    // },

]

const generalStatus = {
    door: [
        { name: "Door A", status: "Open", connected: true },
        { name: "Door B", status: "Close", connected: true },
        { name: "Door C", status: "Open", connected: true },
        { name: "Door D", status: "Close", connected: true },

    ],
    lift: [
        { name: "Lift A", status: "Idle", connected: true },
        { name: "Lift B", status: "Busy", connected: true },
        { name: "Lift C", status: "Lift Called", connected: true },
        { name: "Lift D", status: "AMR Taking lift", connected: true },
    ],
}


export { listOfJobs, robotDetails, listOfAvailableRobots, cardDetails, mapDetails, listOfRobots, generalStatus };
