
interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}

interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    map_uuid?: string;
    layout_uuid?: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
    visibility?: boolean;
}

interface GeneralRobotInfo {
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobDetails2[];
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}


// Dummy robot to simulate movement on mapbox
export class Robot {
  LatLng: [number, number] = [0, 0];
  targetLatLng: [number, number] = [0, 0];
  targetDirection: number = 0;
  direction: number = 0;
  north: number = 0;
  onClick: (robot: Robot) => void = () => {};

  url: string = "";
  path: [number, number][] = [];
  speed = 0.0000002;
  currentPathIndex = 0;

  batteryLevel: number;
  status: string;
  robotId: string;
  robotName: string;
  robotSerialNumber: string;
  connectivity: boolean; // Assuming 'is_online' as connectivity
  map: string;
  map_uuid?: string;
  layout_uuid?: string;
  jobs: JobDetails2[];
  visibility?: boolean;

  constructor(url: string, LatLng: [number, number], robotDetails: RobotStatus2) {
      this.url = url;
      this.LatLng = LatLng;
      this.batteryLevel = robotDetails.battery;
      this.status = robotDetails.robot_state;
      this.robotId = robotDetails.robot_id;
      this.robotName = robotDetails.robot_name;
      this.robotSerialNumber = robotDetails.robot_serial_number;
      this.connectivity = robotDetails.is_online;
      this.map = robotDetails.map;
      this.map_uuid = robotDetails.map_uuid
      this.layout_uuid = robotDetails.layout_uuid
      this.jobs = [];
      this.visibility = robotDetails.visibility ?? true
  }

  setPath(path: [number, number][]): void {
      this.path = path;
  }

  setOnRobotClick(onClick: (robot: Robot) => void): void {
      console.log("Robot clicked");

      this.onClick = onClick;
  }

  click(): void {
      console.log("Robot clicked");
      this.onClick(this);
  }

  setCurrentPosition(LatLng: [number, number]): void {
      this.LatLng = LatLng;
  }

  updateTarget(newTarget: [number, number], newDirection: number): void {
      this.targetLatLng = newTarget;
      this.targetDirection = newDirection > 0 ? newDirection : newDirection + 360;
  }

  setBatteryLevel(battery: number): void {
      this.batteryLevel = battery;
  }

  setRobotStatus(status: string): void {
      this.status = status;
  }

  setRobotName(name: string): void {
      this.robotName = name;
  }

  setVisibility(value: boolean) {
    this.visibility = value;
  }

  move(firstRun = false) {
      if (this.targetLatLng[0] === this.LatLng[0] && 
        this.targetLatLng[1] === this.LatLng[1] &&
        Math.abs(this.targetDirection - this.direction )< 5) return;

      let [lng, lat] = this.LatLng;
      const [targetLng, targetLat] = this.targetLatLng;

      // Interpolate position
      const interpolationFactor = firstRun ? 1 : 0.02; // Adjust this for smoother or quicker movements
      lng += (targetLng - lng) * interpolationFactor;
      lat += (targetLat - lat) * interpolationFactor;

      this.setCurrentPosition([lng, lat]);

      // Check if the robot is close enough to the target to stop moving
      if (Math.abs(lng - targetLng) < 0.00001 && Math.abs(lat - targetLat) < 0.00001) {
          this.setCurrentPosition(this.targetLatLng);
      }

      
      // Handle rotation
      const rotationSpeed = 4; // Degrees per frame, adjust as needed for smoothness
      // console.log(this.direction, this.targetDirection);
      let diff = this.targetDirection - this.direction;

      // // // Normalize the difference to ensure the shortest rotation path
      while (diff < -180) diff += 360;
      while (diff > 180) diff -= 360;

      // Apply a portion of the remaining difference to the current direction
      if (Math.abs(diff) < rotationSpeed) {
          this.direction = this.targetDirection;
      } else {
          this.direction += (diff / Math.abs(diff)) * rotationSpeed;
      }

      //  check if robot rotation is close enough
      if (Math.abs(this.targetDirection - this.direction) < 4) {
          this.direction = this.targetDirection
      }

      // Rotate the robot icon to be upright
      this.north = this.direction > 0 ? -this.direction : Math.abs(this.direction);
  }
}