import { Box, Breadcrumbs, Button, Card, Dialog, DialogContent, DialogTitle, MenuItem, Select, Typography } from "@mui/material";
import swiftbot from '../../assets/swiftbot.png';
import { ArrowForwardIos, Close, KeyboardArrowLeft, X } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteTimeBasedStandByPointMutation, useRobotDetail, useSetDefaultStandByPointMutation, useSetTimeBasedStandByPointMutation, useTimebasedStandbyPoint, useUpdateTimeBasedStandByPointMutation } from "../../hooks/robotDetail";
import { useLocationPoints } from "../../hooks/location";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { weekdays } from "../scheduledJob/_function/common";
import { COLOR } from "../../utils/colors";
import dayjs from "dayjs";
import { TimeBasedStandbyResponseType } from "../../hooks/robotDetail/index.type";

export default function RobotDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showCreateStandbyPoint, setShowStandbyPoint] = useState(false)
  const [showTimeBased, setShowTimeBased] = useState(false)

  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [selectedStartTime, setSelectedStartTime] = useState<Date>()
  const [selectedEndTime, setSelectedEndTime] = useState<Date>()
  const [selectedDefaultLocation, setSelectedDefaultLocation] = useState('')
  const [selectedTimeBased, setSelectedTimeBased] = useState<TimeBasedStandbyResponseType>()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { data: dataTimeBased, refetch: refetchTimeBased } = useTimebasedStandbyPoint({
    robot_id: id
  })
  const timebasedStandbyPoints = dataTimeBased && dataTimeBased.data ? dataTimeBased.data : []
  const { data: dataRobots, isLoading, refetch: refetchRobotData } = useRobotDetail({ robot_id: id })
  const robot = dataRobots && dataRobots.data.length > 0 ? dataRobots.data[0] : undefined

  const { data: dataLocation, refetch: refetchLocationData } = useLocationPoints('waypoint', { pageSize: 100 })
  const locations = dataLocation && dataLocation.data ? dataLocation.data : []

  const { trigger: submitDefaultLocation, isLoading: isSubmitStandbyPoint } = useSetDefaultStandByPointMutation({
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      refetchTimeBased()
    }
  })

  const { trigger: submitTimeBaseStandbyPoint } = useSetTimeBasedStandByPointMutation({
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
    }
  })

  const { trigger: updateTimeBaseStandbyPoint, isLoading: isUpdating } = useUpdateTimeBasedStandByPointMutation(selectedTimeBased ? selectedTimeBased.id : '', {
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
    }
  })

  const { trigger: deleteTimeBaseStandbyPoint, isLoading: isDeleting } = useDeleteTimeBasedStandByPointMutation(selectedTimeBased ? selectedTimeBased.id : '', {
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
      setShowDeleteDialog(false)
    }
  })

  useEffect(() => {
    if (!id) {
      return
    }
    if (!isLoading && !robot && !!dataRobots) {
      // NOT FOUND
      navigate('/robots')
    }
  }, [isLoading])


  const onAddStandbyPoint = (timeBased = false) => {
    setShowStandbyPoint(true)
    setShowTimeBased(timeBased)
    setSelectedTimeBased(undefined)
  }

  const onCloseCreateStandby = () => {
    setShowStandbyPoint(false)
    setSelectedDefaultLocation('')
    setSelectedDays([])
    setSelectedStartTime(undefined)
    setSelectedEndTime(undefined)
  }


  const onSubmitDefaultLocation = () => {
    if (!id || !selectedDefaultLocation) {
      return
    }

    submitDefaultLocation({
      robot_id: id,
      standby_point: selectedDefaultLocation
    })
  }

  const onSubmitTimeBasedStandbyPoint = () => {
    if (!id || !selectedDefaultLocation || !selectedEndTime || !selectedStartTime || selectedDays.length === 0) {
      return
    }

    if (!selectedTimeBased) {
      submitTimeBaseStandbyPoint({
        robot_id: id,
        location_name: selectedDefaultLocation,
        days: selectedDays.map((day) => {
          return day.charAt(0).toUpperCase() + day.substring(1, 3);
        }),
        start_time: dayjs(selectedStartTime).format("HH:mm:ss"),
        end_time: dayjs(selectedEndTime).format("HH:mm:ss")
      })

      return
    }

    updateTimeBaseStandbyPoint({
      location_name: selectedDefaultLocation,
      days: selectedDays.map((day) => {
        return day.charAt(0).toUpperCase() + day.substring(1, 3);
      }),
      start_time: dayjs(selectedStartTime).format("HH:mm:ss"),
      end_time: dayjs(selectedEndTime).format("HH:mm:ss")
    })

  }

  const toggleDay = (day: string) => {
    const tmpRes = [...selectedDays];
    // Already Exist
    if (selectedDays.includes(day)) {
      // Then Remove it
      const findIndex = tmpRes.findIndex((item) => item === day);

      if (findIndex < 0) {
        return
      }

      tmpRes.splice(findIndex, 1);
      setSelectedDays(tmpRes)
      return
    }

    tmpRes.push(day)
    setSelectedDays(tmpRes)
  }

  const findLocation = locations.length > 0 && robot && robot.robot_default_location ? (locations.find((item) => item.location_name === robot.robot_default_location)) : undefined

  const isDisableTimeBased = !selectedDefaultLocation || !selectedStartTime || !selectedEndTime || selectedDays.length === 0

  const onEditTimeBased = (timeBased: TimeBasedStandbyResponseType) => {
    setSelectedTimeBased(timeBased)
    setShowStandbyPoint(true)
    setShowTimeBased(true)

    setSelectedStartTime(dayjs(`2024-01-01 ${timeBased.start_time}`).toDate())
    setSelectedEndTime(dayjs(`2024-01-01 ${timeBased.end_time}`).toDate())

    const days: string[] = []
    timeBased.days?.map((day) => {
      const data = weekdays.find((d) => d.toLowerCase().substring(0, 3) === day.toLowerCase())

      if (!!data) {
        days.push(data)
      }
    })

    setSelectedDays(days)
  }

  const onDeleteTimeBased = () => {
    if (!selectedTimeBased || !id) {
      return
    }
    setShowDeleteDialog(false)
    deleteTimeBaseStandbyPoint({})
  }

  const onShowDeleteDialog = (timeBased: TimeBasedStandbyResponseType) => {
    setSelectedTimeBased(timeBased)
    setShowDeleteDialog(true)
  }

  const onBack = () => {
    navigate('/robots')
  }
  return (
    <>
      {robot && (
        <>
          <Box sx={{ maxWidth: "95%", m: "15px auto", display: "flex", flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>s
                <div onClick={() => onBack()} style={{ cursor: 'pointer' }}>
                  <KeyboardArrowLeft fontSize="large" style={{ color: '#121212' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    
                  >
                    <Typography onClick={() => navigate('/robots')} style={{ cursor: 'pointer', color: '#121212', opacity: 0.5, fontSize: '14px' }}>Robot</Typography>
                    <Typography style={{ color: '#121212', opacity: 0.5, fontSize: '14px' }}>{id}</Typography>
                  </Breadcrumbs>
                  <Typography style={{ color: '#121212', fontSize: '24px', fontWeight: 600 }}>{robot.robot_name}</Typography>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <div>
                  <Card sx={{ padding: '16px' }}>
                    <img src={swiftbot} alt="robot-image" style={{
                      width: '200px',
                      objectFit: 'contain',
                      objectPosition: 'top'
                    }} />
                  </Card>
                </div>
                <div style={{ width: '100%', maxWidth: '75vw', flex: 'auto', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '16px' }}>
                    <Card sx={{ padding: '16px' }}>
                      <div>
                        <Typography fontSize={'12px'} sx={{ opacity: 0.6 }}>Name</Typography>
                        <Typography fontSize={'16px'} fontWeight={600}>{robot.robot_name}</Typography>
                      </div>
                    </Card>
                    <Card sx={{ padding: '16px' }}>
                      <div>
                        <Typography fontSize={'12px'} sx={{ opacity: 0.6 }}>Serial Number</Typography>
                        <Typography fontSize={'16px'} fontWeight={600}>{robot.robot_serial_number}</Typography>
                      </div>
                    </Card>
                    <Card sx={{ padding: '16px' }}>
                      <div>
                        <Typography fontSize={'12px'} sx={{ opacity: 0.6 }}>Mac Address</Typography>
                        <Typography fontSize={'16px'} fontWeight={600}>{robot.robot_mac}</Typography>
                      </div>
                    </Card>
                    <Card sx={{ padding: '16px' }}>
                      <div>
                        <Typography fontSize={'12px'} sx={{ opacity: 0.6 }}>Software Version</Typography>
                        <Typography fontSize={'16px'} fontWeight={600}>10.0.0.7</Typography>
                      </div>
                    </Card>
                  </Box>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                    <Card sx={{ padding: '16px', flexDirection: 'column', flex: 'auto', display: 'flex' }}>
                      <Typography sx={{ fontWeight: 600 }}>Detail Information</Typography>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '14px' }}>
                        <div style={{ maxWidth: '400px' }}>
                          <Typography fontSize={'12px'}>Default Standby Point</Typography>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '18px', marginTop: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100px' }}>
                              <Typography fontSize={'16px'}>{robot.robot_default_location || '-'}</Typography>
                              <Typography fontSize={'12px'} sx={{ opacity: 0.6 }}>{findLocation?.map[0].map_name}</Typography>
                            </div>
                            <Button onClick={() => onAddStandbyPoint()} variant="outlined">{(!!robot.robot_default_location ? "Edit" : "Add") + " Standby Point"}</Button>
                          </div>
                        </div>
                        <div style={{ maxWidth: '400px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography fontSize={'12px'}>Time-based Standby Point</Typography>
                            <Button onClick={() => onAddStandbyPoint(true)} variant="outlined">Add Standby Point</Button>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '8px' }}>
                            {timebasedStandbyPoints.length === 0 && (
                              <div style={{ minWidth: '100px' }}>
                                <Typography fontSize={'16px'}>-</Typography>
                              </div>
                            )}

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', flex: 'auto', gap: '4px' }}>
                              {timebasedStandbyPoints.map((timeBased, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    padding: '8px',
                                    border: `1px solid ${COLOR.gray[300]}`,
                                    borderRadius: '4px'
                                  }}
                                >
                                  <div>
                                    <Typography fontSize={'12px'}>Standby Point</Typography>
                                    <Typography fontSize={'16px'}>
                                      {timeBased.location_name}
                                    </Typography>
                                  </div>
                                  <div style={{ marginTop: '6px' }}>
                                    <Typography fontSize={'12px'}>Time</Typography>
                                    <Typography fontSize={'16px'}>
                                      {timeBased.start_time} - {timeBased.end_time}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography fontSize={'12px'}>Days</Typography>
                                    <Typography fontSize={'16px'}>
                                      {timeBased.days.length === 0 ? "Everyday" : timeBased.days.map((day) => day || '').join(', ')}
                                    </Typography>
                                  </div>
                                  <div style={{ display: 'flex', marginTop: '8px', gap: '8px', flexDirection: 'row', width: '100%' }}>
                                    <Button onClick={() => onEditTimeBased(timeBased)} variant="outlined" style={{ flex: 'auto' }}>Edit</Button>
                                    <Button onClick={() => onShowDeleteDialog(timeBased)} variant="outlined" color="error" style={{ flex: 'auto' }}>Delete</Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>

                    {
                      showCreateStandbyPoint && !showTimeBased && (
                        <Card sx={{ padding: '16px', minWidth: '400px', flex: 'none' }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                            <div>
                              <Typography fontWeight={600}>
                                {(!!robot.robot_default_location ? "Update" : "Create") + " Standby Point"}
                              </Typography>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={() => onCloseCreateStandby()}>
                              <Close />
                            </div>
                          </div>

                          <div>
                            <Typography fontSize={'12px'}>Current Standby Point</Typography>
                            <Typography>{robot.robot_default_location || '- (Not Defined)'}</Typography>
                          </div>
                          <div style={{ marginTop: '8px' }}>
                            <Typography fontSize={'12px'}>New Standby Point</Typography>
                            <Select sx={{ width: '100%' }} size="small" value={selectedDefaultLocation} onChange={(e) => setSelectedDefaultLocation(e.target.value)}>
                              {locations.map((location, index) => (
                                <MenuItem
                                  key={index}
                                  value={location.location_name}
                                >
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '4px', paddingBottom: '4px' }}>
                                    <span>{location.location_name}</span>
                                    <span style={{ fontSize: '12px' }}>{location.map[0].map_name}</span>
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '12px' }}>
                              <Button onClick={() => onCloseCreateStandby()} variant="outlined">Cancel</Button>
                              <LoadingButton loading={isSubmitStandbyPoint} onClick={onSubmitDefaultLocation} disabled={!selectedDefaultLocation} variant="contained">
                                Save
                              </LoadingButton>
                            </div>
                          </div>
                        </Card>
                      )
                    }

                    {showCreateStandbyPoint && showTimeBased && (
                      <Card sx={{ padding: '16px', minWidth: '400px', flex: 'none' }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          <div>
                            <Typography fontWeight={600}>
                              {"Create Time-based Standby Point"}
                            </Typography>
                          </div>
                          <div style={{ cursor: 'pointer' }} onClick={() => onCloseCreateStandby()}>
                            <Close />
                          </div>
                        </div>

                        {selectedTimeBased && (
                          <div>
                            <Typography fontSize={'12px'}>Current Standby Point</Typography>
                            <Typography>{selectedTimeBased.location_name || '- (Not Defined)'}</Typography>
                          </div>
                        )}
                        <div style={{ marginTop: '8px' }}>
                          <div>
                            <Typography fontSize={'12px'}>New Time-based Standby Point</Typography>
                            <Select sx={{ width: '100%' }} size="small" value={selectedDefaultLocation} onChange={(e) => setSelectedDefaultLocation(e.target.value)}>
                              {locations.map((location, index) => (
                                <MenuItem
                                  key={index}
                                  value={location.location_name}
                                >
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '4px', paddingBottom: '4px' }}>
                                    <span>{location.location_name}</span>
                                    <span style={{ fontSize: '12px' }}>{location.map[0].map_name}</span>
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <div style={{ marginTop: '8px' }}>
                              <Typography fontSize={'12px'}>Start Time</Typography>
                              <TimePicker
                                sx={{
                                  '& .MuiInputBase-input': {
                                    paddingTop: '8.5px',
                                    paddingBottom: '8.5px',
                                  },
                                  width: '100%'
                                }}
                                value={selectedStartTime ? dayjs(selectedStartTime) : undefined}
                                onChange={(val) => setSelectedStartTime(val?.toDate())}
                              />
                            </div>
                            <div style={{ marginTop: '8px' }}>
                              <Typography fontSize={'12px'}>End Time</Typography>
                              <TimePicker
                                sx={{
                                  '& .MuiInputBase-input': {
                                    paddingTop: '8.5px',
                                    paddingBottom: '8.5px',
                                  },
                                  width: '100%'
                                }}
                                value={selectedEndTime ? dayjs(selectedEndTime) : undefined}
                                onChange={(val) => setSelectedEndTime(val?.toDate())}
                              />
                            </div>
                          </LocalizationProvider>

                          <div style={{ marginTop: '8px' }}>
                            <Typography fontSize={'12px'}>Days</Typography>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '4px',
                                alignItems: 'center',
                              }}
                            >
                              {weekdays.map((day, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 'auto',
                                    width: '32px',
                                    height: '32px',
                                    backgroundColor: selectedDays.includes(day) ? COLOR.accent.green : COLOR.gray[200],
                                    color: selectedDays.includes(day) ? COLOR.neutral[50] : COLOR.gray[600],
                                    borderRadius: '999px',
                                    aspectRatio: '1/1',
                                    userSelect: 'none',
                                    cursor: 'pointer'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    toggleDay(day)
                                  }}
                                >
                                  <Typography fontSize={12} sx={{ textTransform: 'capitalize' }}>{day.substring(0, 3)}</Typography>
                                </div>
                              ))}
                            </div>

                          </div>

                          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '12px' }}>
                            <Button onClick={() => onCloseCreateStandby()} variant="outlined">Cancel</Button>
                            <LoadingButton loading={isSubmitStandbyPoint || isUpdating} onClick={onSubmitTimeBasedStandbyPoint} disabled={isDisableTimeBased} variant="contained">
                              Save
                            </LoadingButton>
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box >

          <Dialog maxWidth={'sm'} fullWidth={true} open={showDeleteDialog} onClose={() => {
            setShowDeleteDialog(false)
            setSelectedTimeBased(undefined)
          }}>
            <DialogTitle fontSize={16} fontWeight={500}>Are you sure want to delete?</DialogTitle>
            <DialogContent>
              {selectedTimeBased && (
                <div>
                  <div>
                    <Typography fontSize={'12px'}>Standby Point</Typography>
                    <Typography fontSize={'16px'}>
                      {selectedTimeBased.location_name}
                    </Typography>
                  </div>
                  <div style={{ marginTop: '6px' }}>
                    <Typography fontSize={'12px'}>Time</Typography>
                    <Typography fontSize={'16px'}>
                      {selectedTimeBased.start_time} - {selectedTimeBased.end_time}
                    </Typography>
                  </div>
                  <div>
                    <Typography fontSize={'12px'}>Days</Typography>
                    <Typography fontSize={'16px'}>
                      {selectedTimeBased.days.length === 0 ? "Everyday" : selectedTimeBased.days.map((day) => day || '').join(', ')}
                    </Typography>
                  </div>
                </div>
              )}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: '12px',
                gap: '4px'
              }}>
                <Button onClick={() => {
                  setShowDeleteDialog(false)
                  setSelectedTimeBased(undefined)
                }}>Cancel</Button>
                <Button onClick={() => onDeleteTimeBased()} variant="contained" color="error" disableElevation>Yes, Delete</Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  )
}