import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CalendarContentProps } from "./Calendar.types";
import dayjs from "dayjs";
import { getJobTypeLabel } from "../_function/common";
import { columns } from "./ListColumn";
import { Button, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export default function ListView({
  dateContents,
  month,
  year,
  handleSelectDate,
  onSelectRow
}: {
  month: string,
  year: string,
  handleSelectDate?: (date: Date) => void,
  dateContents?: CalendarContentProps[],
  onSelectRow?: (data: CalendarContentProps) => void
}) {
  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '8px'
      }}>
        <div className="calendar-header">
          <Typography variant="body1" textAlign={'left'}>
            {month} {year}
          </Typography>
        </div>

        <div style={{
          display: "flex",
          flexDirection: 'row'
        }}>
          <Button
            sx={{
              color: '#23c667'
            }}
            onClick={() => handleSelectDate?.(dayjs(year + '-' + month + '-01').subtract(1, 'month').toDate())}>
            <ArrowBack />
          </Button>
          <Button
            sx={{
              color: '#23c667'
            }}
            onClick={() => handleSelectDate?.(dayjs(year + '-' + month + '-01').add(1, 'month').toDate())}
          >
            <ArrowForward />
          </Button>
        </div>
      </div>
      <DataGrid
        rows={dateContents}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25]}
        // checkboxSelection
        // disableRowSelectionOnClick
        disableMultipleRowSelection
        onRowClick={(param) => onSelectRow?.(param.row)}
      />
    </div>
  )
}