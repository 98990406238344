import React, { CSSProperties } from 'react';
import { Box, Grid, Card, Typography, Divider } from '@mui/material';
import robots from '../../../assets/new_icons/robot.png';
import idle from '../../../assets/new_icons/idle.png';
import busy from '../../../assets/new_icons/busy.png';
import offline from '../../../assets/new_icons/offline.png';
import job from '../../../assets/job.png';

interface AggregateCardProps {
  title: string;
  data: number;
  clicked: boolean;
}

const robotStyle: CSSProperties = {
  aspectRatio: '112/94',
}

const idleStyle: CSSProperties = {
  aspectRatio: '90/83',
}

const busyStyle: CSSProperties = {
  aspectRatio: '96/96',
}

const offlineStyle: CSSProperties = {
  aspectRatio: '67/80',
}

const AggregateCard: React.FC<AggregateCardProps> = ({ title, data, clicked }) => {
  const getIconStyles = (title: string) : CSSProperties => {
    switch (title) {
      case 'Total Robots':
        return robotStyle

      case 'Idle': 
        return idleStyle

      case 'Busy':
        return busyStyle

      case 'Offline':
        return offlineStyle

      default:
        return {
          aspectRatio: '1/1'
        }
        break;
    }
  }
  return (
    <Grid item xs={12} md={3} sx={{mt:-4}}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: clicked ? "rgba(24, 26, 51, 0.8)" : "white", 
          cursor: "pointer", 
          width: "100%",
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.05)",
          '&:hover' : {
            boxShadow: "1px 3px 3px 1px rgba(0, 0, 0, 0.075)",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '12px',
            ml: '14px',
            mr: '24px',
            gap: '6px'
            // mr:2, 
            // height: "60px",
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: "#9E9E9E", fontSize: '16px' }}>
            {title}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
            <img
              src={
                title === "Total Robots"
                  ? robots
                  : title === "Idle"
                  ? idle
                  : title === "Busy"
                  ? job
                  : offline
              }
              alt="icon"
              style={{
                ...getIconStyles(title),
                height: 50,
              }}
            />
            <Typography variant="h4" sx={{ color: "#000", marginLeft: "30px", mt: 1 }} >
                {data}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default AggregateCard;
