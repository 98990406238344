import React, { useRef, useState } from 'react';
import { Box, Table,Button, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, TextField, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
// Expanded UserData and FeatureData interfaces
interface UserData {
    username: string;
    email: string;
    role: string;
    default_location: string;
    feature: FeatureData;
}

interface FeatureData {
    RobotMonitoring: {
        ViewRobotLocationsOnMap: boolean;
        ViewRobotStatuses: boolean;
        ViewNotificationAlerts: boolean;
        ViewCriticalAlerts: boolean;
    };
    JobManagement: {
        CreateNewJob: boolean;
        ViewAllJob: boolean;
        CreateScheduledJob: boolean;
        CancelScheduledJob: boolean;
        ViewJobsCreatedbyUser: boolean;
        UpdateJobsOwn: boolean;
        UpdateJobsAll: boolean;
        CancelJobsOwn: boolean;
        CancelJobsAll: boolean;
    };
    NotificationManagement: {
        ReceiveGeneralJobNotificationSelf: boolean;
        ReceiveNotificationAtDeliveryLocationAsRecipient: boolean;
        ReceiveNotificationAtPickUpLocationAsSender: boolean;
        ReceiveCriticalSystemNotifications: boolean;
        ManageNotificationPreferences: boolean;
    };
    ReportLogs: {
        GenerateAllJobReports: boolean;
        ViewRobotLogs: boolean;
    };
    UserManagement: {
        ViewAllUsers: boolean;
        AddOrRemoveUsers: boolean;
        AssignRoles: boolean;
    };
    UserProfileManagement: {
        ConfigureUserProfileSelf: boolean;
        AssignUserProfileLocation: boolean;
    };
    MapManagement: {
        ConfigureRobotMapLayout: boolean;
        AddOrRemoveMarkersOnMap: boolean;
        ManageMapLayoutAndRobotMap: boolean;
        AddOrRemoveRobotsFromMap: boolean;
    };
    RobotManagement: {
        ViewRobotInformation: boolean;
        AddOrRemoveRobots: boolean;
        UpdateRobotInformation: boolean;
    };
}

const EditUser = () => {
    const paperRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    // Initialize the userData with feature permissions and user information
    const [userData, setUserData] = useState<UserData>({
        username: '',
        email: '',
        role: '',
        default_location: '',
        feature: {
            RobotMonitoring: {
                ViewRobotLocationsOnMap: false,
                ViewRobotStatuses: false,
                ViewNotificationAlerts: false,
                ViewCriticalAlerts: false,
            },
            JobManagement: {
                CreateNewJob: false,
                ViewAllJob: false,
                CreateScheduledJob: false,
                CancelScheduledJob: false,
                ViewJobsCreatedbyUser: false,
                UpdateJobsOwn: false,
                UpdateJobsAll: false,
                CancelJobsOwn: false,
                CancelJobsAll: false,
            },
            NotificationManagement: {
                ReceiveGeneralJobNotificationSelf: false,
                ReceiveNotificationAtDeliveryLocationAsRecipient: false,
                ReceiveNotificationAtPickUpLocationAsSender: false,
                ReceiveCriticalSystemNotifications: false,
                ManageNotificationPreferences: false,
            },
            ReportLogs: {
                GenerateAllJobReports: false,
                ViewRobotLogs: false,
            },
            UserManagement: {
                ViewAllUsers: false,
                AddOrRemoveUsers: false,
                AssignRoles: false,
            },
            UserProfileManagement: {
                ConfigureUserProfileSelf: false,
                AssignUserProfileLocation: false,
            },
            MapManagement: {
                ConfigureRobotMapLayout: false,
                AddOrRemoveMarkersOnMap: false,
                ManageMapLayoutAndRobotMap: false,
                AddOrRemoveRobotsFromMap: false,
            },
            RobotManagement: {
                ViewRobotInformation: false,
                AddOrRemoveRobots: false,
                UpdateRobotInformation: false,
            },
        },
    });

    // Handler for text input
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handler for checkbox input with proper typing for featureGroup and featureName
    const handleCheckboxChange = <T extends keyof FeatureData, K extends keyof FeatureData[T]>(
        featureGroup: T,
        featureName: K
    ) => {
        setUserData((prevState) => ({
            ...prevState,
            feature: {
                ...prevState.feature,
                [featureGroup]: {
                    ...prevState.feature[featureGroup],
                    [featureName]: !prevState.feature[featureGroup][featureName],
                },
            },
        }));
    };

    // Handle select/deselect all for a feature group
    const handleSelectAll = <T extends keyof FeatureData>(featureGroup: T, selectAll: boolean) => {
        setUserData((prevState) => ({
            ...prevState,
            feature: {
                ...prevState.feature,
                [featureGroup]: Object.keys(prevState.feature[featureGroup]).reduce((acc, key) => {
                    acc[key as keyof FeatureData[T]] = selectAll as FeatureData[T][keyof FeatureData[T]]; // Ensure we are assigning a boolean value
                    return acc;
                }, {} as FeatureData[T]), // Initialize with empty object of FeatureData[T] type
            },
        }));
    };

    // Function to render checkboxes for each feature group with Select All checkbox
   // Function to render checkboxes for each feature group with Select All checkbox
    const renderFeatureGroup = <T extends keyof FeatureData>(featureGroup: T) => {
        const featureGroupData = userData.feature[featureGroup];
        const allSelected = Object.values(featureGroupData).every((val) => val);

        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${featureGroup}-content`} id={`${featureGroup}-header`}>
                    <Typography variant="h6" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "14px" }}>{camelToSpace(featureGroup)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {/* Select All Checkbox */}
                        <FormControlLabel
                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' }, mt:-2 }}
                            control={
                                <Checkbox
                                    size="small"
                                    indeterminate={!allSelected && Object.values(featureGroupData).some((val) => val)}
                                    checked={allSelected}
                                    onChange={() => handleSelectAll(featureGroup, !allSelected)}
                                />
                            }
                            label="Select All"
                        />
                        
                        {/* Indent feature checkboxes under "Select All" */}
                        {Object.keys(featureGroupData).map((featureKey) => (
                            <FormControlLabel
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    width: "50%",
                                    ml: 3,  // Add margin-left to indent
                                    '& .MuiFormControlLabel-label': { fontSize: '14px' } 
                                }}
                                key={featureKey}
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={userData.feature[featureGroup][featureKey as keyof FeatureData[T]] as boolean}
                                        onChange={() => handleCheckboxChange(featureGroup, featureKey as keyof FeatureData[T])}
                                        name={featureKey}
                                    />
                                }
                                label={camelToSpace(featureKey)}  // Convert camelCase to Camel Space
                            />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    };
    const camelToSpace = (str: string) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }).trim();
    };

    return (
        <div style={{ flexGrow: "1", display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: "#F7F7F7", alignItems: "center" }}>
            <Paper ref={paperRef} elevation={5} sx={{ display: 'flex', flexDirection: 'column', borderRadius: "5px", margin: "30px auto", overflow: "auto", width: "80%" }}>
                {/* Header */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', ml: 5, mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb:1 }}>
                        <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "24px" }}>
                            Edit User Group
                        </Typography>
                        <IconButton onClick={() => navigate("/access-control")} sx={{ mt: -1, mr:2 }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "12px", mb: 1 }}>
                        Please update the fields accordingly and click on update to save the changes.
                    </Typography>
                </Box>

                {/* User Information Table */}
                <TableContainer
                    sx={{
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        width: "90%",
                        ml: 5,
                    }}
                >
                    <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        padding: '8px',
                                        width: "30%",
                                        borderRight: "1px solid #E0E0E0",
                                    }}>
                                    Username
                                </TableCell>
                                <TableCell align="left" sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px", padding: '8px' }}>
                                    <TextField
                                        name="username"
                                        value={userData.username}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            border: "none",
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                            },
                                            "& fieldset": { border: 'none' },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        padding: '8px',
                                        width: "30%",
                                        borderRight: "1px solid #E0E0E0",
                                    }}>
                                    Email
                                </TableCell>
                                <TableCell align="left" sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px", padding: '8px' }}>
                                    <TextField
                                        name="email"
                                        value={userData.email}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            border: "none",
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                            },
                                            "& fieldset": { border: 'none' },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        padding: '8px',
                                        width: "30%",
                                        borderRight: "1px solid #E0E0E0",
                                    }}>
                                    Role
                                </TableCell>
                                <TableCell align="left" sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px", padding: '8px' }}>
                                    <TextField
                                        name="role"
                                        value={userData.role}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            border: "none",
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                            },
                                            "& fieldset": { border: 'none' },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        padding: '8px',
                                        width: "30%",
                                        borderRight: "1px solid #E0E0E0",
                                    }}>
                                    Default Location
                                </TableCell>
                                <TableCell align="left" sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px", padding: '8px' }}>
                                    <TextField
                                        name="default_location"
                                        value={userData.default_location}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            border: "none",
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                            },
                                            "& fieldset": { border: 'none' },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Feature Permissions Table */}
                <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", mt: 4, ml: 6 }}>
                    Feature Permissions
                </Typography>

                <TableContainer
                    sx={{
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        width: "90%",
                        mt: 4,
                        ml: 6,
                        mb: 4,
                    }}
                >           
                    {Object.keys(userData.feature).map((featureGroup) => (
                        renderFeatureGroup(featureGroup as keyof FeatureData)
                    ))}
                </TableContainer>

                {/* Save Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            console.log('User Data:', userData);
                            // Add save logic here
                        }}
                    >
                        Update User
                    </Button>
                </Box>
            </Paper>
        </div>
    );
};

export default EditUser;
