// features/map/mapSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapDetails2, MapDetails3 } from '../types';

interface MapState {
  zoomLevel: number;
  mapDetails: MapDetails3[];
  isPickUpSelected: boolean;
  isDropOffSelected: boolean;
  senderLocation?: string;
  recipientLocation?: string;
  senderAndRecipientSelected: boolean;
  currentFloor: string;
}

const initialState: MapState = {
  zoomLevel: 18, // Default zoom level
  mapDetails: [],
  isPickUpSelected: false,
  isDropOffSelected: false,
  senderLocation: "",
  recipientLocation: "",
  senderAndRecipientSelected: false,
  currentFloor: "3",
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    storeMapPoints: (state, action: PayloadAction<MapDetails3[]>) => {
      state.mapDetails = action.payload;
    },
    zoomIn: (state) => {
      state.zoomLevel = Math.min(state.zoomLevel + 1, 22);
    },
    zoomOut: (state) => {
      state.zoomLevel = Math.max(state.zoomLevel - 1, 0);
    },
    setZoomLevel: (state, action: PayloadAction<number>) => {
      state.zoomLevel = action.payload;
    },
    setPickUpSelected: (state, action: PayloadAction<boolean>) => {
      state.isPickUpSelected = action.payload;
    },
    setDropOffSelected: (state, action: PayloadAction<boolean>) => {
      state.isDropOffSelected = action.payload;
    },
    setSenderLocation: (state, action: PayloadAction<string>) => {
      state.senderLocation = action.payload;
    },
    setRecipientLocation: (state, action: PayloadAction<string>) => {
      state.recipientLocation = action.payload;
    },
    setSenderAndRecipientSelected: (state, action: PayloadAction<boolean>) => {
      state.senderAndRecipientSelected = action.payload;
    },
    setCurrentFloor: (state, action: PayloadAction<string>) => {
      state.currentFloor = action.payload
    },
    resetFloor: (state) => {
      state.currentFloor = initialState.currentFloor
    }

  },
});

export const { zoomIn, zoomOut, setZoomLevel, storeMapPoints, setPickUpSelected, setDropOffSelected, setSenderLocation, setRecipientLocation, setSenderAndRecipientSelected, setCurrentFloor, resetFloor } = mapSlice.actions;

export default mapSlice.reducer;
