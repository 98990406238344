import { JobEnums } from "./enums/JobEnums";

const getStatusColor = (status: string) => {
    const normalizedStatus = status.toLowerCase();
    switch (normalizedStatus) {
        case JobEnums.ARRIVED_AT_DESTINATION:
        case JobEnums.ITEM_DELIVERED:
            return '#00C48C';
        case JobEnums.CANCELLED:
        case JobEnums.DELIVERY_FAILED:
        case JobEnums.PICKED_UP_FAILED:
        case JobEnums.RETURN_FAILED:
        case JobEnums.RETURN_TO_SENDER:
            return '#FF0B0B';
        case JobEnums.QUEUED:
        case JobEnums.PICKING_UP:
        case JobEnums.ITEM_LOADED:
        case JobEnums.DELIVERING:
        case JobEnums.ARRIVED_AT_DELIVERING:
        case JobEnums.ARRIVED_AT_PICKUP:
        case JobEnums.ITEM_LOADED:
        case JobEnums.ITEM_DELIVERED:
        case JobEnums.ASSIGNED:
        case JobEnums.ASSIGNING_COMPARTMENT:
        case JobEnums.NOT_ASSIGNED:
            return '#378FFE';
        default:
            return 'black';
    }
};

export { getStatusColor };