import { CSSProperties, useState } from "react";
import './Calendar.css'
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { CalendarContentProps } from "./Calendar.types";
import dayjs from "dayjs";
import { ArrowBack, ArrowForward, ArrowLeftTwoTone } from "@mui/icons-material";
import { months, weekdays } from "../_function/common";

function CalendarDays({
  day,
  onSelectDate,
  contents
}: {
  day: Date,
  onSelectDate?: (date: Date, content?: CalendarContentProps) => void,
  contents?: CalendarContentProps[];
}) {
  let firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1);
  let weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let dayNum = 0; dayNum < 42; dayNum++) {
    if (dayNum === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (dayNum === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (dayNum - weekdayOfFirstDay));
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: (firstDayOfMonth.getMonth() === day.getMonth()),
      date: (new Date(firstDayOfMonth)),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: (firstDayOfMonth.toDateString() === day.toDateString()),
      year: firstDayOfMonth.getFullYear()
    }

    currentDays.push(calendarDay);
  }

  const findContents = (date: Date): CalendarContentProps[] => {
    if (!contents || contents.length === 0) {
      return []
    }
    const dateFormat = "DD/MM/YYYY"
    return contents.filter((item) => dayjs.unix(item.timestamp).format(dateFormat) === dayjs(date).format(dateFormat)) || []
  }

  const handleSelectContent = (date: Date, content?: CalendarContentProps) => {
    onSelectDate?.(date, content)
  }

  const getColorCode = (data: CalendarContentProps): CSSProperties => {
    switch (data.job_type) {
      case 'adhoc_job':
        return {
          backgroundColor: '#EA4C1D',
          color: '#FFFFFF'
        }

      case 'charging_job':
        return {
          backgroundColor: '#0AC97F',
          color: '#FFFFFF'
        }

      case 'pick_up_job':
        return {
          backgroundColor: '#9D56F7',
          color: '#FFFFFF'
        }

      case 'delivery_job':
      default:
        return {
          backgroundColor: '#4946C5',
          color: '#FAFAFA'
        }
    }
  }

  return (
    <div className="table-content">
      {
        currentDays.map((day, idx) => {
          return (
            <div key={idx} className={"calendar-day" + (day.currentMonth ? " current" : "") + (day.selected ? " selected" : "")}
              onClick={() => onSelectDate?.(day.date, findContents(day.date).length > 0 ? findContents(day.date)[0] : undefined)}
            >
              <Typography variant="body1">{day.number}</Typography>
              {
                findContents(day.date) && findContents(day.date).length > 0 && (
                  <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: '2px', bottom: '8px', left: '8px', right: '8px' }}>
                    {findContents(day.date).map((item, index) => index < 2 && (
                      <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSelectContent(day.date, item)
                      }} key={index} style={{
                        ...getColorCode(item),
                        userSelect: 'none',
                        borderRadius: '4px',
                        display: "flex",
                        flexDirection: "column",
                        cursor: 'pointer',
                      }}>
                        <div style={{ padding: '6px', display: 'flex', flexDirection: 'row' }}>
                          <span style={{
                            fontFamily: "Roboto, Hevetica, sans-serif",
                            fontSize: '12px',
                            marginRight: '4px'
                          }}>
                            {dayjs.unix(item.timestamp).format("HH:mm")}
                          </span>
                          <span style={{
                            flex: 'auto',
                            fontFamily: "Roboto, Hevetica, sans-serif",
                            fontSize: '12px',
                            display: "-webkit-box",
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 1,
                          }}>
                            {item.title ?? item.id}
                          </span>
                          {
                            findContents(day.date).length > 2 && index === 1 && (
                              <span style={{
                                fontFamily: "Roboto, Hevetica, sans-serif",
                                fontSize: '12px',
                                fontWeight: 700
                              }}>
                                {findContents(day.date).length - 2}+
                              </span>
                            )
                          }
                        </div>
                      </div>
                    ))
                    }
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}
export default function Calendar({
  selectedDate,
  onSelectDate,
  dateContents
}: {
  selectedDate?: Date,
  onSelectDate?: (date: Date, content?: CalendarContentProps) => void;
  dateContents?: CalendarContentProps[]
}) {
  const [currentDate, setCurrentDate] = useState(selectedDate ?? new Date());

  const handleSelectDate = (date: Date, content?: CalendarContentProps) => {
    setCurrentDate(date)
    onSelectDate?.(date, content)
  }

  return (
    <div className="calendar" style={{
      color: '#000000',
      marginBottom: '12px'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '8px'
      }}>
        <div className="calendar-header">
          <Typography variant="body1" textAlign={'left'}>
            {months[currentDate.getMonth()]} {currentDate.getFullYear()}
          </Typography>
        </div>

        <div style={{
          display: "flex",
          flexDirection: 'row'
        }}>
          <Button
            sx={{
              color: '#23c667'
            }}
            onClick={() => handleSelectDate(dayjs(selectedDate).subtract(1, 'month').toDate())}>
            <ArrowBack />
          </Button>
          <Button
            sx={{
              color: '#23c667'
            }}
            onClick={() => handleSelectDate(dayjs(selectedDate).add(1, 'month').toDate())}
          >
            <ArrowForward />
          </Button>
        </div>
      </div>
      <div className="calendar-body">
        <div className="table-header">
          {
            weekdays.map((weekday, index) => {
              return <div className="weekday" key={index}>
                <Typography variant="subtitle1">{weekday}</Typography>
              </div>
            })
          }
        </div>
        <div className="table">
          <CalendarDays
            day={currentDate}
            onSelectDate={(date, content) => handleSelectDate(date, content)}
            contents={dateContents}
          />
        </div>
      </div>
    </div>
  )
}