import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Stack, IconButton, InputAdornment } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Notification from '../../../components/Notification'; 
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface LoginFormState {
    username: string;
    password: string;
}

interface LoginFormProps {
    isMobile: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ isMobile }) => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { isLoggedIn } = useAuth();
    const [form, setForm] = useState<LoginFormState>({
        username: '',
        password: '',
    });
    
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const queryProfile = async (accessToken: string) => {
        console.log("querying profile");
        await axios({ 
          method: "GET",
          url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/auth/profile/`,
          headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        }).then((response) => {
            console.log("checkingggg", response.data);
          localStorage.setItem('profile', JSON.stringify(response.data));
        }
        ).catch((error) => {
          console.error(error);
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        console.log("form", form);
        console.log(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/auth/login/`)
        event.preventDefault();
        await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/auth/login/`,
            headers: {
              "Content-Type": "application/json",
            },
            data: form,
          })
          .then ((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                login(response.data.access, response.data.refresh);
                queryProfile(response.data.access);
                setSnackbarMessage('Login successful. Redirecting to dashboard...');
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            } 
          })
          .catch(function (error) {
            if (error.response.status === 401) {
                console.log("error", error.response.data.error);
                setSnackbarMessage('Invalid credentials. Please try again.');
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
            else{
                setSnackbarMessage('Something went wrong. Please try again.');
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
          });
    }

    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', margin: "auto 10px",   }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: "auto 10px",   }}>
                <Typography variant="h5" sx={{ mt: 2, fontWeight: 600 }}>
                    Welcome Back
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Sign in to continue RoboHub
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
                    <TextField
                        name="username"
                        // label="Username"
                        variant="outlined"
                        margin="normal"
                        placeholder="Username"
                        fullWidth
                        value={form.username}
                        onChange={handleFieldChange}
                        InputLabelProps={{
                            shrink: true  // This ensures the label always stays floated
                        }}
                    />
                    <TextField
                        name="password"
                        // label="Password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={form.password}
                        onChange={handleFieldChange}
                        InputLabelProps={{
                            shrink: true,  // This ensures the label always stays floated
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                            <Typography variant="body2" sx={{ color: '#A0A0A0', fontSize: '12px' }}>
                                Forgot password?
                            </Typography>
                        </Link>
                    </Box>
                    <Button type="submit" variant="contained" sx={{ mt: 2, width: '100%', backgroundColor: '#12B76A', mb:4 }}>
                        Login
                    </Button>
                </Box>
            </Box>
            <Notification
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                snackbarMessage={snackbarMessage}
                snackbarSeverity={snackbarSeverity}
                vertical="bottom"
                horizontal="right"
            />
        </Box>
    );
};

export default LoginForm;
