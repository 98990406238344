import { useMutation, useRequest } from "../../utils/api"
import { RequestConfigType, ShortMutationConfigType } from "../../utils/api.type"
import { DefaultStandbyResponseType, RobotResponseType, SetDefaultStandbyPointProps, SetTimeBasedStandbyPointProps, TimeBasedResponseType, TimeBasedStandbyResponseType, UpdateTimeBasedStandbyPointProps } from "./index.type"

const GET_ROBOT = '/v1/rms/robot/'
interface RobotListProps extends RequestConfigType {
  pageSize?: number
  robot_id?: string
}
export const useRobotDetail = (obj = {} as RobotListProps) => {
  return useRequest<RobotResponseType>(GET_ROBOT, {
    ...obj,
    pageSize: obj.pageSize || 20,
    robot_id: obj.robot_id || '-'
  }, {
    enabled: obj.enabled && !!obj.robot_id
  })
}


const STORE_DEFAULT_LOCATION = '/v1/rms/robot/config/'
export const useSetDefaultStandByPointMutation = (options: ShortMutationConfigType) => {
  return useMutation<DefaultStandbyResponseType, SetDefaultStandbyPointProps>(STORE_DEFAULT_LOCATION, options)
}

const STORE_TIMEBASED_LOCATION = '/v1/rms/robot/config/schedule/'
export const useSetTimeBasedStandByPointMutation = (options: ShortMutationConfigType) => {
  return useMutation<TimeBasedStandbyResponseType, SetTimeBasedStandbyPointProps>(STORE_TIMEBASED_LOCATION, options)
}

const GET_TIMEBASED_STANDBY_POINT = '/v1/rms/robot/config/schedule/'
export const useTimebasedStandbyPoint = (obj = {} as RobotListProps) => {
  return useRequest<TimeBasedResponseType>(GET_TIMEBASED_STANDBY_POINT, {
    ...obj,
    pageSize: obj.pageSize || 20,
    robot_id: obj.robot_id || '-'
  }, {
    enabled: obj.enabled && !!obj.robot_id
  })
}


const UPDATE_TIMEBASED_LOCATION = '/v1/rms/robot/config/schedule/:id/'
export const useUpdateTimeBasedStandByPointMutation = (id: string, options: ShortMutationConfigType) => {
  return useMutation<TimeBasedStandbyResponseType, UpdateTimeBasedStandbyPointProps>(UPDATE_TIMEBASED_LOCATION.replace(':id', id), { ...options, method: 'PUT' })
}

const DELETE_TIMEBASED_LOCATION = '/v1/rms/robot/config/schedule/:id/'
export const useDeleteTimeBasedStandByPointMutation = (id: string, options: ShortMutationConfigType) => {
  return useMutation<any, {}>(DELETE_TIMEBASED_LOCATION.replace(':id', id), { ...options, method: 'DELETE' })
}