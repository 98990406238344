import { CalendarContentProps } from "../../routes/scheduledJob/_component/Calendar.types"
import { defaultRequestConfig, useMutation, useRequest } from "../../utils/api"
import { MutationConfigType, RequestConfigType, ShortMutationConfigType } from "../../utils/api.type"
import { StoreScheduleJobProps } from "./index.type"

const GET_RECURRING_JOB = '/v1/rms/recurring_job/projection/'
interface RecurringJobProps extends RequestConfigType {
  start_time?: Date,
  end_time?: Date
}
export const useScheduledJobs = (obj = {} as RecurringJobProps) => {
  return useRequest<CalendarContentProps[]>(GET_RECURRING_JOB, {
    ...obj,
    start_time: obj.start_time ? Math.floor(new Date(obj.start_time).getTime() / 1000) : undefined,
    end_time: obj.end_time ? Math.floor(new Date(obj.end_time).getTime() / 1000) : undefined,
  }, {
    enabled: obj.enabled
  })
}

const STORE_RECURRING_JOB = '/v1/rms/recurring_job/'
export const useCreateScheduledJob = (options: ShortMutationConfigType) => {
  return useMutation<StoreScheduleJobProps, any>(STORE_RECURRING_JOB, options)
}