import { Box, Typography } from "@mui/material";
import { ElevatorRounded, ElevatorTwoTone } from "@mui/icons-material";
import LiftIcon from "../../_icons/lift";
export default function LiftPointMarker({
  name,
  handleLocationSelect,
  rotation
}: {
  name: string,
  handleLocationSelect?: (name: string) => void,
  rotation?: number
}) {
  return (
    <>
      <Box
        onClick={() => handleLocationSelect?.(name)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
          position: 'relative',
          transform: `rotate(${rotation || 0}deg)`
        }}
      >
        <LiftIcon />
        <Typography
          variant="subtitle2"
          sx={{
            color: '#1565C0',
            fontSize: '14px',
            textAlign: "center",
            wordBreak: 'keep-all',
            width: '100%'
          }}
        >
          {name}
        </Typography>
      </Box>
    </>
  )
}