import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, Stepper, Step, StepLabel, styled, StepConnector, StepContent } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import swiftbot from '../../../assets/swiftbot.png'; 
import { useWebSocket } from "../../../components/useWebSocket";
import { JobEnums } from "../../../utils/enums/JobEnums";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getStatusColor } from "../../../utils/ColourStatus";
import { JobData2, JobDetails3 } from "../../../store/types";
import axios from 'axios';
import { useRobotDetail } from "../../../hooks/robotDetail";
interface JobDetailsProps {
    imageHeight: number;
    job: JobDetails2;
    handleJobBoardClose: () => void;
    activeStep: number;
    handleBackButton: () => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    job_status: string;
    order_number: string;
}

interface Step {
    label: string;
    text: string;
    time: string;
    id: string;
}

interface JobHistory {
    [order_number: string]: JobDetails2[];
}

const adhocStep: Step[] = [
  {
    label: "Robot Going to Assigned Location",
    text: "Robot is coming to assigned Location",
    time: "..." ,
    id: JobEnums.DISPATCHING
  },
  { 
    label: "Arrived at Assigned Location",
    text: "Robot has arrived at assigned Location",
    time: "..." ,
    id: JobEnums.ARRIVED_AT_DESTINATION
  }
] 

const deliveryStep: Step[] = [
  { label: "Dispatching", 
    text: "Your item is on the way", 
    time: "..." , 
    id: JobEnums.DELIVERING
  },
  { label: "Robot Arrived", 
    text: "Your item has arrived", 
    time: "..." , 
    id: JobEnums.ARRIVED_AT_DELIVERING
  },
  { label: "Delivered", 
    text: "Your item has been delivered", 
    time: "..." , 
    id: JobEnums.ITEM_DELIVERED
  }
]

const cancelledStep: Step = {
  label: "Cancelled",
  text: "Delivery has been cancelled",
  time: "...",
  id: JobEnums.CANCELLED
}

const JobDetails: React.FC<JobDetailsProps> = ({ job, handleJobBoardClose, imageHeight, activeStep, handleBackButton }) => {

    const statusToStepIndex = {
        [JobEnums.PICKING_UP]: 0,
        [JobEnums.ARRIVED_AT_PICKUP]: 1,
        [JobEnums.ITEM_LOADED]: 2,
        [JobEnums.DELIVERING]: 3,
        [JobEnums.ARRIVED_AT_DELIVERING]: 4,
        [JobEnums.ITEM_DELIVERED]: 5,
    };

    const statusDeliveryToStepIndex = {
      [JobEnums.DELIVERING]: 0,
      [JobEnums.ARRIVED_AT_DELIVERING]: 1,
      [JobEnums.ITEM_DELIVERED]: 2,
    }
      
    const statusAdhocToStepIndex = {
      [JobEnums.DISPATCHING]: 0,
      [JobEnums.ARRIVED_AT_DESTINATION]: 1
    }

    const statusCancelledToStepIndex = {
      [JobEnums.CANCELLED]: 0
    }

    const getSteps = (jobType: string) => {
      switch (jobType) {
        case "adhoc_job":
        case "charging_job":
          return adhocStep;

        case "delivery_job":
          return deliveryStep;

        default:
          break;
      }
      return steps3
    }

    const getStatusToStepIndex = (jobType: string) => {
      switch (jobType) {
        case "adhoc_job":
        case "charging_job":
          return statusAdhocToStepIndex;

        case "delivery_job":
          return statusDeliveryToStepIndex;

        default:
          break;
      }
      return statusToStepIndex
    }

    const [jobDetails, setJobDetails] = useState<JobHistory>({});
    const [currentStep, setCurrentStep] = useState(-1);
    const [steps3, setSteps3] = useState<Step[]>([
        { label: "Robot Going to Pick Up", text: "Robot is coming to pick up your order", time: "...", id: JobEnums.PICKING_UP  },
        { label: "Arrived at Pick Up", text: "Robot has arrived to pick up your order", time: "..." , id: JobEnums.ARRIVED_AT_PICKUP},
        { label: "Order Picked Up", text: "Your item has been picked up", time: "..." , id: JobEnums.ITEM_LOADED},
        { label: "Dispatching", text: "Your item is on the way", time: "..." , id: JobEnums.DELIVERING},
        { label: "Robot Arrived", text: "Your item has arrived", time: "..." , id: JobEnums.ARRIVED_AT_DELIVERING},
        { label: "Delivered", text: "Your item has been delivered", time: "..." , id: JobEnums.ITEM_DELIVERED}
    ]);

    const getJobHistory = async (order_number: string) => {
        console.log("Getting jobs history...", order_number);
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_logs/?order_number=${order_number}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response history", response.data);
                const jobData = response.data.data;
                const jobDetails: JobHistory = {};
                jobData.forEach((job: JobDetails2) => {
                    const existingJobs = jobDetails[job.order_number] || [];
                    jobDetails[job.order_number] = [...existingJobs, job];
                });

                setJobDetails(jobDetails);
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    const cancelJob = async (job: JobDetails2) => {
        console.log("Cancelling job...", job);
        await axios({
            method: "PUT",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/assigned_job/${job.id}/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            data: {
                job_status: JobEnums.CANCELLED
            }
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response cancel", response.data);
                // get job history after cancelling job
                getJobHistory(job.order_number);

            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    const handleJobHistoryUpdates = useCallback((data: JobDetails2) => {
        console.log("Job history updates:", data)
        setJobDetails(prevJobDetails => {
            const existingJobs = prevJobDetails[data.order_number] || [];
            if (!existingJobs.find(job => job.timestamp === data.timestamp && job.job_status === data.job_status)) {
                return {
                    ...prevJobDetails,
                    [data.order_number]: [...existingJobs, data]
                };
            }
            return prevJobDetails;
        });
    }, []);

    useEffect(() => {
        console.log("Job details 2:", jobDetails);
        console.log("Job detail page:", job, jobDetails[job.order_number]);
        if (jobDetails[job.order_number] == undefined) {
            console.log("Getting job history...");
            getJobHistory(job.order_number);
        }
    }, [jobDetails]);


    useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/delivery/${job.id}/`, handleJobHistoryUpdates);

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',     
            month: 'short',     
            year: 'numeric'     
        };
        return date.toLocaleDateString('en-GB', options);
    };
    
    const removeNoTimeStep = (steps: Step[]) => {
      const results: Step[] = [];

      steps.forEach(element => {
        if (element.time !== '...') {
          results.push(element)
        }
      });

      return results
    }

    const addCancelledStep = (steps: Step[], jobHistory: JobDetails2[]) => {
      const results = [...steps]
      const jobInfo = jobHistory.find(j => j.job_status === cancelledStep.id);

      results.push({
        ...cancelledStep,
        time: jobInfo ? `${formatDate(jobInfo.timestamp)}, ${new Date(jobInfo.timestamp * 1000).toLocaleTimeString()}` : cancelledStep.time
      })

      return results
    }

    // useEffect(() => {
    //     if (jobDetails[job.order_number]?.length > 0) {
    //         const sortedJobs = jobDetails[job.order_number].sort((a, b) => a.timestamp - b.timestamp);
    //         const newSteps = sortedJobs.map(job => ({
    //             label: job.job_status.replace(/_/g, ' ').toUpperCase(),
    //             text: text[statusToStepIndex[job.job_status] || 0],
    //             time: formatDate(job.timestamp) + " " + new Date(job.timestamp * 1000).toLocaleTimeString(),
    //             id: statusToStepIndex[job.job_status] || 0,
    //         }));
    //         setSteps2(newSteps.sort((a, b) => a.time.localeCompare(b.time)));
    //         setCurrentStep(statusToStepIndex[job.job_status] || 0);
    //         console.log("New steps:", newSteps);
    //         console.log("Current step:", currentStep);
    //     }
    // }, [jobDetails, job.order_number]);

    useEffect(() => {
        if (jobDetails[job.order_number]?.length > 0) {
            const jobType = jobDetails[job.order_number][0].job_type
            const jobStatus = jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status
            const steps = getSteps(jobType)
            const tmpUpdatedSteps = steps.map(step => {
                const jobInfo = jobDetails[job.order_number].find(j => j.job_status === step.id);
                console.log(jobInfo);
                return jobInfo ? {
                    ...step,
                    time: `${formatDate(jobInfo.timestamp)}, ${new Date(jobInfo.timestamp * 1000).toLocaleTimeString()}`
                } : step;
            });
            const updatedSteps = [JobEnums.CANCELLED].includes(jobStatus) ? addCancelledStep(removeNoTimeStep(tmpUpdatedSteps), jobDetails[job.order_number]) : tmpUpdatedSteps
            console.log("Updated steps:", updatedSteps);
            const statToStepIndex = getStatusToStepIndex(jobType)

            // console.log("current step", jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status) 
            setCurrentStep(statToStepIndex[jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status] || -1);

            // job status is picked up failed, cancelled or queued, set current step to -1
            if (jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.PICKED_UP_FAILED 
                // || jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.CANCELLED 
                || jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.QUEUED 
                ) {
                setCurrentStep(-1);
            }

            // job status delivery failed, return failed, return to sender, set current step to the the step before failed
            if (jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.DELIVERY_FAILED 
                || jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.RETURN_FAILED 
                || jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.RETURN_TO_SENDER 
                || jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.PICKED_UP_FAILED
                ) {
                    console.log("failed status", statToStepIndex[jobDetails[job.order_number][jobDetails[job.order_number].length - 2].job_status])
                // setCurrentStep(statusToStepIndex[jobDetails[job.order_number][jobDetails[job.order_number].length - 2].job_status] - 1);
                setCurrentStep(statToStepIndex[jobDetails[job.order_number][jobDetails[job.order_number].length - 2].job_status] || -1);
            }

            // if item delivered, +1 to current step
            if (jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.ITEM_DELIVERED) {
                setCurrentStep(statToStepIndex[jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status] + 1 || -1);
            }

            if (jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.CANCELLED){
              setCurrentStep(updatedSteps.length)
            }
            
            setSteps3(updatedSteps);
        }
    }, [jobDetails]);


    const CustomConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            borderColor: '#9F9F9F', // Set the color of the connector
            borderWidth: 5, // Increase the thickness of the line
            borderTopWidth: 15,
            height: 75,
            left: 10,
        },
    }));

    const { data: robotData } = useRobotDetail({ robot_id: job.robot_id, enabled: !!job.robot_id })
    const robot = robotData && robotData.data && robotData.data.length > 0 ? robotData.data[0] : undefined  

    return ( 
        <>
            <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",  height: "230px", display: "flex", flexDirection: "column",}}>
                <Stack sx={{
                    position: "absolute",
                    top: "20px",
                    left: "15px",
                }}>
                    <IconButton onClick={handleBackButton}>
                        <ArrowBackIosNewIcon sx={{ color: "#000", fontSize:"15px" }} />
                    </IconButton>
                </Stack>
                <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    m:1.5,
                    ml:4.5

                }}>
                <Stack sx={{width: "60px", height:"60px", bgcolor:"#f4f4f4", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center" }}>
                    <img src={swiftbot} style={{ width: "50px", height: "50px" }} />
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", m:1, ml:4 }}>
                    <Typography sx={{ color: "#000", fontSize:"20px", fontWeight:"600" }}>
                        Job #{job.order_number.slice(-5).toUpperCase()}
                    </Typography>
                    <Typography sx={{ color: getStatusColor(job.job_status), fontSize:"14px" }}>
                        {/* {job.job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())} */}
                        {jobDetails[job.order_number]?.length > 0 ? 
                            `${jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())} 
                            ${jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status === JobEnums.CANCELLED ? `at ${new Date(jobDetails[job.order_number][jobDetails[job.order_number].length - 1].timestamp * 1000).toLocaleTimeString()}` : ""}`
                            : job.job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())
                        }
                    </Typography>
                </Box>
                <Stack sx={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                }}>
                    <IconButton onClick={handleJobBoardClose}>
                        <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                    </IconButton>
                </Stack>
            </Stack>
            
            <Box sx={{ display: "flex", flexDirection: "row", width: "85%", margin:"0 auto", justifyContent:"space-between", mt:-1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Robot
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        { (job.robot_id ? (robot ? robot.robot_name : job.robot_id) : "Not Assigned")}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Pick Up
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        {job.sender_location}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Drop Off
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        {job.recipient_location}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{display: "flex", flexDirection: "row", justifyContent:"space-between", ml: 3, mt: 2, mb:2, mr:3}}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                    <AccessTimeIcon sx={{ color: "#9F9F9F", fontSize:"20px", mr: 1.5, }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                        <Typography sx={{ color: "#000", fontSize:"14px" }}>
                            Estimated Time
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize:"12px" }}>
                            {/* {job.estimatedTime} */}
                            20mins
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left", }}>
                    {/* cancel job */}
                    <Button 
                        variant="outlined" 
                        color="error" 
                        disabled = {job.job_status === "item_delivered" || job.job_status === "cancelled" 
                        || job.job_status === "return_failed" || job.job_status === "return_to_sender" 
                        || job.job_status === "delivery_failed" || job.job_status === "picked_up_failed" 
                        || job.job_status === "arrived_at_pickup" || job.job_status === "arrived_at_delivering"
                        || job.job_status === "arrived_at_destination" 
                        }
                        sx={{  fontSize:"12px", fontWeight:"600", p:1, }}
                        onClick = {() => cancelJob(job)}
                        >
                        Cancel Job
                    </Button>
                </Box>
            </Box>
            </Box>
            <Box sx={{overflow:"auto", flex: 'auto'}}>
            <Box sx={{ display: "flex", flex: 'auto', flexDirection: "row", width: "90%", margin:"0 auto", justifyContent:"space-between", mt:2 }}>
            <Stepper activeStep={currentStep} orientation="vertical" sx={{ padding: 2, }} connector={<CustomConnector />}>
                {steps3.map((step, index) => (
                    <Step key={index} completed={currentStep > index}  sx={{mt:-6.2, mb:-6.2, ml:0.2}} >
                        <StepLabel sx={{
                            '& .MuiStepLabel-label': {
                                textAlign: 'left',
                                mt: 5, 
                                ml:2,
                                fontSize: "16px"

                            },
                            '& .MuiStepConnector-line': {
                                borderColor: '#9F9F9F', 
                                borderWidth: 5, 
                            }
                            }}
                            >
                            {step.label}
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5, fontSize: "14px" }}>
                                {step.text}
                                <br />
                                {step.time}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            </Box>
        </Box>
        </>
     );
}
 
export default JobDetails;