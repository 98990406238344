import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import flashbot from '../../assets/flashbot.png';
import swiftbot from '../../assets/swiftbot.png';

import batteryFull from '../../assets/batteryFull.png';
import battery75 from '../../assets/battery75.png';
import battery25 from '../../assets/battery25.png';
import batteryCharging from '../../assets/batteryCharging.png';
import battery0 from '../../assets/battery0.png';
// import { useSelector, useDispatch } from 'react-redux';

interface RobotIconProps {
    robot: {
        name: string;
        status: string;
        battery: number;
    }
}

const RobotIcon: React.FC<RobotIconProps> = ({ robot }) => {
//   const isLegendOn = useSelector((state: any) => state.toggleLegend.isLegendOn);
    
    // const legendOpen = useSelector((state: any) => state.toggleLegend.isLegendOn);
    // useEffect(() => {
    //     console.log('robotIcon', isLegendOn);
    // }, [isLegendOn]);

    const [batteryIcon, setBatteryIcon] = React.useState<string>('');
    const [statusColor, setStatusColor] = React.useState<string>('');
    useEffect(() => {
        setBatteryIcon(getBatteryIcon(robot.battery));
        setStatusColor(getStatusColor(robot.status));
    }, [robot]);

    const getBatteryIcon = (battery: number) => {
        if (battery >= 75) return batteryFull;
        if (battery < 75 && battery >= 40) return battery75;
        if (battery < 40 && battery >= 10) return battery25;
        if (battery < 10 && battery > 0) return battery0;
        return batteryCharging;
    }

    

    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'idle':
                return '#12B76A';
            case 'busy':
                return '#0094FF';
            case 'charging':
                return '#12B76A';
            case 'offline':
                return '#FF0000';
            case 'error':
                return '#FF0000';
            case 'Free':
                return '#12B76A';
            case 'free':
                return '#12B76A';
            case 'localisation_error':
                return '#FF0000';
            case 'hardware_error':
                return '#FF0000';
            case 'stuck':
                return '#FF0000';
            default:
                return '#000000';
        }
    }
    
    const getRobotColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'idle':
                return {
                    backgroundColor: 'rgba(45, 249, 147, 0.26)',
                    border: '#23C666'
                };
            case 'busy':
                return {
                    backgroundColor: 'rgba(255, 175, 78, 0.26)',
                    border: '#FFB031'
                };
                
            case 'charging':
                return {
                    backgroundColor: 'rgba(45, 249, 147, 0.26)',
                    border: '#23C666'
                };
            case 'offline':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };
            case 'error':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };
            case 'Free':
                return {
                    backgroundColor: 'rgba(45, 249, 147, 0.26)',
                    border: '#23C666'
            };
            case 'free':
                return {
                    backgroundColor: 'rgba(45, 249, 147, 0.26)',
                    border: '#23C666'
            };
            case 'localisation_error':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };
            case 'hardware_error':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };
            case 'stuck':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };

            case 'estop':
                return {
                    backgroundColor: 'rgba(255, 99, 78, 0.26)',
                    border: '#FC1D1D'
                };

            default:
                return {
                    backgroundColor: 'rgba(255, 255, 255, 0.26)',
                    border: '#000000'
                }
        }
    }
    return (
        <Box sx={{
            zIndex: 100001,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            // border: "3px solid #5FD8FF", 
            border: `3px solid ${getRobotColor(robot.status).border}`,
            borderRadius: '50%',
            position: 'relative',
            width: 40,
            height: 40, 
            backgroundColor: getRobotColor(robot.status).backgroundColor,
            '& img': {
                width: '40px',
                height: 'auto', 
            },
        }}>
            <div style={{
                position: 'absolute', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}>
                <img src={swiftbot} alt="Robot" />
                {/* {isLegendOn && <img src={batteryIcon} alt="Battery" style={{ position: 'absolute', top: -40, left: '50%', transform: 'translateX(-50%)', width: 30, height: 30 }} /> } */}
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: 'black',
                        fontSize: '14px',
                        mt: 0.5,
                        textAlign: 'center',
                        position: 'absolute',
                        top: 70,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ml: -1
                    }}
                >
                    {robot?.name}
                </Typography>
                {/* {isLegendOn &&
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: statusColor,
                        fontSize: '12px',
                        mt: 0.5,
                        textAlign: 'center',
                        position: 'absolute',
                        top: 90,
                        width: '60px',
                        ml: -0.6,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        
                    }}
                >
                    {robot?.status[0].toUpperCase() + robot?.status.slice(1).toLowerCase()}
                </Typography>
                } */}

            </div>

            <Box sx={{
                position: 'absolute',
                top: -10, 
                left: '50%',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent', 
                borderRight: '10px solid transparent', 
                borderBottom: `10px solid ${getRobotColor(robot.status).border}`,
                transform: 'translateX(-50%)',
            }} 
            />
        </Box>
    );
}

export default RobotIcon;
