import { Button, Collapse, Typography } from "@mui/material";
import { CalendarContentProps } from "./Calendar.types";
import dayjs from "dayjs";
import { DeleteOutlineTwoTone, EditOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { ReactNode } from "react";
import JobForm from "./Form";
import { COLOR } from "../../../utils/colors";
import { getJobTypeLabel } from "../_function/common";

export default function ShowDataCard({
  content,
  onClickContent,
  appendNode,
  selectedJobId,
  isEdit,
  onCancelEdit,
  onSaveEdit,
  onEdit,
  onDelete,
  ...props
}: {
  content?: CalendarContentProps,
  onClickContent?: (data: CalendarContentProps) => void,
  selectedJobId?: string,
  isEdit?: boolean,
  appendNode?: ReactNode,
  onCancelEdit?: () => void,
  onSaveEdit?: () => void,
  onEdit?: (data: CalendarContentProps) => void,
  onDelete?: (data: CalendarContentProps) => void
}) {
  return content ? (
    <div
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
      }}
      onClick={() => onClickContent?.(content)}
    >
      <div
        style={{
          padding: '8px',
          border: '1px solid #e7e7e7c3',
          borderRadius: '6px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography sx={{
            fontSize: '14px',
            // color: content.bgColor,
            color: COLOR.accent.green,
            fontWeight: 600,
            flex: 'auto'
          }}>
            {content.title ?? (content?.id)}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#9ca3af'
            }}
          >
            {dayjs.unix(content.timestamp).format("HH:mm")}
          </Typography>
          {
            appendNode
          }
        </div>
        {
          content && (
            <Collapse in={selectedJobId === content.id + '-' + content.timestamp}>
              {isEdit ? (
                <JobForm form={content} onCancelEdit={() => onCancelEdit?.()} onEdit={() => onSaveEdit?.()} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px'
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <div>
                              <Typography
                                fontSize={12}
                                color={COLOR.gray[400]}
                              >
                                Job Id
                              </Typography>
                              <Typography
                                fontSize={14}
                                fontWeight={600}
                                color={COLOR.gray[800]}
                              >
                                {content.jobDetail.job_id}
                              </Typography>
                            </div> */}
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Description
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {'-'}
                    </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Task Type
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {getJobTypeLabel(content.job_type)}
                    </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Robot
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      -
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Sender Name
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {content.sender_name}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Sender Location
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {content.sender_location}
                    </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Recipient Name
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {content.recipient_name}
                    </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Delivery Location
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {content.recipient_location}
                    </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Start On
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                    >
                      {dayjs.unix(content.timestamp).format("DD MMM YYYY HH:mm")}
                    </Typography>
                  </div>
                  {/* <div >
                                  <Typography
                                    fontSize={12}
                                    color={COLOR.gray[400]}
                                  >
                                    Status
                                  </Typography>
                                  <Typography
                                    fontSize={14}
                                    fontWeight={600}
                                    color={COLOR.gray[800]}
                                  >
                                    {content.jobDetail.status}
                                  </Typography>
                                </div> */}
                  <div>
                    <Typography
                      fontSize={12}
                      color={COLOR.gray[400]}
                    >
                      Repeat
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={COLOR.gray[800]}
                      textTransform={"capitalize"}
                    >
                      {content.recurring_type}
                    </Typography>
                  </div>
                  {/* <div>
                                <div>
                                  <Typography>
                                    Repeat Type
                                  </Typography>
                                  <Select>
                                    <MenuItem value="All">All Robots</MenuItem>
                                  </Select>
                                </div>
                              </div> */}
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                    marginTop: '4px'
                  }}>
                    <Button onClick={() => onEdit?.(content)} sx={{ width: '100%', flex: 'auto' }} variant="outlined" disableElevation>
                      <EditOutlined fontSize="small" />
                      Edit
                    </Button>
                    <Button onClick={() => onDelete?.(content)} sx={{ width: '100%', flex: 'auto' }} color="error" variant="outlined" disableElevation>
                      <DeleteOutlineTwoTone fontSize="small" />
                      Delete
                    </Button>
                  </div>

                </div>
              )}
            </Collapse>
          )
        }
      </div>
    </div>
  ) : (<></>)
}