import { Button, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import { COLOR } from "../../../utils/colors"
import { CalendarContentProps } from "./Calendar.types"
import { useEffect, useState } from "react"
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useRobots } from "../../../hooks/robot"
import { useLocationPoints } from "../../../hooks/location"
import { timeStamp } from "console"
import { OptionsObject, useSnackbar } from "notistack"
import { useCreateScheduledJob } from "../../../hooks/scheduled_jobs"

const taskTypes = [
  {
    name: 'Delivery',
    value: 'delivery_job'
  }, {
    name: 'Charging',
    value: 'charging_job'
  }, {
    name: 'Go To',
    value: 'go_to_job'
  }
]

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thusday', 'friday', 'saturday'];

// const locations = [
//   {
//     name: "Point A",
//   },
//   {
//     name: "Point B",
//   },
//   {
//     name: "Point C",
//   },
//   {
//     name: "Point D",
//   }
// ]

export default function JobForm({
  form,
  onEdit,
  onCancelEdit,
  onCreate,
  onCancelCreate
}: {
  form?: CalendarContentProps
  onEdit?: (data?: CalendarContentProps) => void
  onCancelEdit?: () => void,
  onCreate?: (data?: CalendarContentProps) => void,
  onCancelCreate?: () => void
}) {
  const { data: dataRobot, isLoading: isLoadingRobot } = useRobots();
  const robots = dataRobot?.data || [];

  const [content, setContent] = useState<CalendarContentProps>()
  // Dummy Form Value

  // Form
  const [selectedRepeat, setSelectedRepeat] = useState('do_not_repeat')
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskType, setTaskType] = useState('')
  const [selectedRobot, setSelectedRobot] = useState('');
  const [senderLocation, setSenderLocation] = useState('');
  const [recipientLocation, setRecipientLocation] = useState('');
  const [selectedTime, setSelectedTime] = useState<Date | undefined>(new Date());
  const [endRepeatType, setEndRepeatType] = useState('none');
  const [endByOccurence, setEndByOccurence] = useState('1');
  const [endByDate, setEndByDate] = useState<Date | undefined>(new Date());

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const resetForm = () => {
    setSelectedRepeat('do_not_repeat')
    setSelectedDays([]);
    setTaskName('')
    setTaskDescription('')
    setTaskType('')
    setSelectedRobot('')
    setSenderLocation('')
    setRecipientLocation('')
    setSelectedTime(new Date())
    setEndRepeatType('none')
    setEndByOccurence('1')
    setEndByDate(new Date())
  }
  const { trigger: submitScheduleJob, isLoading: isMutatingScheduled } = useCreateScheduledJob({
    onSuccess: () => {
      resetForm()
      onCreate?.()
    }
  })

  const { data: dataLocation, isLoading: isLoadingLocation, refetch: refetchLocation } = useLocationPoints(taskType === 'charging_job' ? 'charging_point' : 'waypoint');
  const locations = dataLocation?.data || [];

  useEffect(() => {
    if (!selectedTime) {
      return
    }
    if (selectedRepeat && !['weekly', 'biweekly'].includes(selectedRepeat)) {
      setSelectedDays([])
      return
    }
    const dayNum = selectedTime?.getDay();
    const dayName = weekdays[dayNum];

    if (!selectedDays.includes(dayName)) {
      const tmpDays = selectedDays.length <= 1 ? [] : [...selectedDays]
      tmpDays.push(dayName)
      setSelectedDays(tmpDays)
    }
  }, [selectedRepeat, selectedTime])

  useEffect(() => {
    if (!taskType || !dataLocation) { return }
    console.log(taskType);

    refetchLocation()
  }, [taskType])

  const showErrorToast = (message: string, option: OptionsObject = {}) => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top'
      },
      style: { fontFamily: "Poppins" },
      ...option
    })
    return
  }

  const subDayName = (days: string[]) => {
    return days.map((day) => {
      return day.charAt(0).toUpperCase() + day.substring(1, 3).toLowerCase()
    })
  }

  const onSubmitCreate = () => {
    if (!taskType) {
      showErrorToast("Please Select Task Type")
      return
    }
    if (!selectedRobot) {
      showErrorToast("Plase Select Robot")
      return
    }
    if (['delivery_job'].includes(taskType) && !senderLocation) {
      showErrorToast("Please Select Pickup Location")
      return
    }

    if (!recipientLocation) {
      showErrorToast(['delivery_job'].includes(taskType) ? "Please Select Delivery Location" : "Please Select the Location")
      return
    }

    if (!selectedTime) {
      showErrorToast("Please Choose the Time")
      return
    }

    if (['weekly', 'biweekly'].includes(selectedRepeat) && selectedDays.length === 0) {
      showErrorToast("Please Repeat Day")
      return
    }

    const form = {
      title: taskName,
      descripion: taskDescription,
      job_type: taskType,
      required_compartment: 1,
      robot_id: selectedRobot === 'near' ? "" : selectedRobot,
      sender_location: senderLocation,
      sender_name: 'John',
      recipient_name: 'Kelvin',
      recipient_location: recipientLocation,
      timestamp: parseInt((selectedTime.getTime() / 1000) + ''),
      recurring_type: selectedRepeat,
      days: ['weekly', 'biweekly'].includes(selectedRepeat) ? subDayName(selectedDays) : [],
      occurence: endRepeatType === 'by_occurance' ? parseInt(endByOccurence) : undefined,
      timestamp_end: endRepeatType === 'by_date' && endByDate ? parseInt((endByDate.getTime() / 1000) + '') : undefined
    }
    submitScheduleJob(form)
  }

  useEffect(() => {
    if (!form) {
      return
    }
    setContent(form)
  }, [form])

  const toggleDay = (day: string) => {
    const tmpRes = [...selectedDays];
    // Already Exist
    if (selectedDays.includes(day)) {
      // Then Remove it
      const findIndex = tmpRes.findIndex((item) => item === day);

      if (findIndex < 0) {
        return
      }

      tmpRes.splice(findIndex, 1);
      setSelectedDays(tmpRes)
      return
    }

    tmpRes.push(day)
    setSelectedDays(tmpRes)
  }


  const getSelectedRepeatLabel = () => {
    switch (selectedRepeat) {
      case 'daily':
        return 'Day';

      case 'weekly':
        return 'Week';

      case 'monthly':
        return 'Month'
      default:
        return selectedRepeat
        break;

    }
  }


  const isShowPickup = ['delivery_job'].includes(taskType)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div >
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Task Name (Optional)
          </Typography>
          <TextField
            sx={{
              width: '100%'
            }}
            size="small"
            defaultValue={content?.title || content?.id}
            maxRows={4}
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            placeholder="Task Name (Optional)"
          />
        </div>
        <div >
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Description (Optional)
          </Typography>
          <TextField
            sx={{
              width: '100%'
            }}
            size="small"
            defaultValue={content?.description}
            multiline
            minRows={2}
            maxRows={4}
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            placeholder="Task Description (Optional)"
          />
        </div>
        <div >
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Task Type
          </Typography>
          <Select
            sx={{
              width: '100%'
            }}
            size="small"
            defaultValue={content?.job_type}
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
            placeholder="Task Type"
          >
            {taskTypes.map((option, index) => (
              <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Robot
          </Typography>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Select
              sx={{
                width: '100%'
              }}
              size="small"
              defaultValue={''}
              value={selectedRobot}
              onChange={(e) => setSelectedRobot(e.target.value)}
              placeholder="Select Robot"
            >
              {
                isLoadingRobot && (
                  <MenuItem>Loading...</MenuItem>
                )
              }
              {!isLoadingRobot && (
                <MenuItem value="near">Any Robot</MenuItem>
              )}
              {robots.map((option, index) => (
                <MenuItem key={index} value={option.robot_mac}>{option.robot_name}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {isShowPickup && (
          <div>
            <Typography
              fontSize={12}
              color={COLOR.gray[400]}
            >
              Pickup Location
            </Typography>
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Select
                sx={{
                  width: '100%'
                }}
                size="small"
                defaultValue={content?.sender_location}
                value={senderLocation}
                onChange={(e) => setSenderLocation(e.target.value)}
                MenuProps={{
                  sx: {
                    maxHeight: '400px',
                  }
                }}
                displayEmpty
                placeholder="Pickup Location"
              >
                {!isLoadingLocation && locations.length === 0 && (
                  <MenuItem disabled value="">No Location Available</MenuItem>
                )}
                {locations.map((option, index) => (
                  <MenuItem key={index} value={option.location_name}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>{option.location_name}</span>
                      <span className="text-xs" style={{ color: COLOR.gray[400] }}>{option.map[0].building}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
        <div>
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            {isShowPickup ? 'Delivery Location' : 'Location'}
          </Typography>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Select
              sx={{
                width: '100%'
              }}
              size="small"
              defaultValue={content?.recipient_location}
              value={recipientLocation}
              onChange={(e) => setRecipientLocation(e.target.value)}
              MenuProps={{
                sx: {
                  maxHeight: '400px',
                }
              }}
            >
              {!isLoadingLocation && locations.length === 0 && (
                <MenuItem disabled value="">No Location Available</MenuItem>
              )}
              {locations.map((option, index) => (
                <MenuItem key={index} value={option.location_name}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{option.location_name}</span>
                    <span className="text-xs" style={{ color: COLOR.gray[400] }}>{option.map[0].building}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div >
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Start On
          </Typography>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '6px'
          }}>
            <div
              style={{
                flex: 'auto'
              }}
            >
              <DateTimePicker
                sx={{
                  '& .MuiInputBase-input': {
                    paddingTop: '8.5px',
                    paddingBottom: '8.5px',
                  },
                  width: '100%'
                }}
                // defaultValue={content?.timestamp ? dayjs.unix(content?.timestamp) : undefined}
                value={selectedTime ? dayjs(selectedTime) : undefined}
                onChange={(date) => setSelectedTime(date?.toDate())}
              />
            </div>
          </div>
        </div>
        <div>
          <Typography
            fontSize={12}
            color={COLOR.gray[400]}
          >
            Repeat
          </Typography>
          <Select
            sx={{
              width: '100%'
            }}
            size="small"
            value={selectedRepeat}
            onChange={(e) => setSelectedRepeat(e.target.value)}
          >
            <MenuItem value={'do_not_repeat'}>None</MenuItem>
            <MenuItem value={'daily'}>Daily</MenuItem>
            <MenuItem value={'weekly'}>Weekly</MenuItem>
            <MenuItem value={'biweekly'}>Bi-Weekly</MenuItem>
            <MenuItem value={'monthly'}>Monthly</MenuItem>
          </Select>
        </div>
        {selectedRepeat && selectedRepeat !== 'do_not_repeat' && false && (
          <div
            style={{
              display: 'flex',
              flex: 'row',
              alignItems: 'center',
              gap: '4px',
              paddingTop: '6px',
              paddingBottom: '6px',
            }}
          >
            <Typography sx={{ flex: 'none', fontSize: '12px' }}>
              Repeat every
            </Typography>
            <Input defaultValue={1} />
            <Typography sx={{ flex: 'none', fontSize: '12px', mr: '24px' }}>
              {getSelectedRepeatLabel()}
            </Typography>
          </div>
        )}
        {(selectedRepeat === 'weekly' || selectedRepeat === 'biweekly') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
              marginTop: '4px'
            }}
          >
            {weekdays.map((day, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 'auto',
                  width: '32px',
                  height: '32px',
                  backgroundColor: selectedDays.includes(day) ? COLOR.accent.green : COLOR.gray[200],
                  color: selectedDays.includes(day) ? COLOR.neutral[50] : COLOR.gray[600],
                  borderRadius: '999px',
                  aspectRatio: '1/1',
                  userSelect: 'none',
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDay(day)
                }}
              >
                <Typography fontSize={12} sx={{ textTransform: 'capitalize' }}>{day.substring(0, 3)}</Typography>
              </div>
            ))}
          </div>
        )
        }
        {selectedRepeat && selectedRepeat !== 'do_not_repeat' && (

          <div style={{ marginTop: '6px' }}>
            <Typography
              fontSize={12}
              color={COLOR.gray[400]}
            >
              End Repeat
            </Typography>
            <RadioGroup
              value={endRepeatType}
              onChange={(e) => setEndRepeatType(e.target.value)}
            >
              <FormControlLabel style={{ marginTop: '8px', marginRight: '0px', marginLeft: '0px' }} value="none" control={<Radio size="small" />} label={
                <Typography fontSize={14}>None</Typography>
              } />
              <FormControlLabel style={{ marginTop: '8px', marginRight: '0px', marginLeft: '0px' }} value="by_occurance" control={<Radio size="small" />} label={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize={14}>On</Typography>
                  <TextField
                    defaultValue={1}
                    variant="outlined"
                    type="number"
                    size="small"
                    value={parseInt(endByOccurence)}
                    onChange={(e) => {
                      setEndRepeatType('by_occurance')
                      setEndByOccurence(e.target.value.toString())
                    }}
                  />
                  <Typography fontSize={12} color={COLOR.gray[500]}>Occurence(s)</Typography>
                </div>
              } />
              <FormControlLabel style={{ marginTop: '8px', marginRight: '0px', marginLeft: '0px' }} value="by_date" control={<Radio size="small" />} label={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                    alignItems: 'baseline'
                  }}
                >
                  <Typography fontSize={14}>Before</Typography>
                  <DateTimePicker
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingTop: '8.5px',
                        paddingBottom: '8.5px',
                      },
                      width: '100%',
                      flex: 'auto'
                    }}
                    minDate={selectedTime ? dayjs(selectedTime) : undefined}
                    // defaultValue={content?.timestamp ? dayjs(content?.timestamp) : undefined}
                    value={endByDate ? dayjs(endByDate) : undefined}
                    onChange={(e) => {
                      setEndRepeatType('by_date')
                      setEndByDate(e?.toDate())
                    }}
                  />
                </div>
              } />
            </RadioGroup>
          </div>
        )}
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          marginTop: '8px'
        }}>
          {!!form ? (
            <>
              <Button onClick={() => onCancelEdit?.()} sx={{ width: '100%', flex: 'auto' }} color="info" variant="outlined" disableElevation>
                Cancel
              </Button>
              <Button onClick={() => content && onEdit?.(content)} sx={{ width: '100%', flex: 'auto' }} variant="outlined" disableElevation>
                Save
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => onCancelCreate?.()} sx={{ width: '100%', flex: 'auto' }} color="info" variant="outlined" disableElevation>
                Cancel
              </Button>
              <Button onClick={(e) => {
                // e.stopPropagation()
                onSubmitCreate()
              }} sx={{ width: '100%', flex: 'auto' }} variant="contained" disableElevation>
                Create
              </Button>
            </>
          )}
        </div>

      </div>
    </LocalizationProvider>
  )
}