import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {useState, useEffect} from 'react';
import axios from "axios";
import {useNavigate} from 'react-router-dom';


interface RobotTableProps {
    actions?: string[];
    type?: string;
    columns: Column[];
    items: RobotsData;
}

interface Column {
    id: string;
    label: string;
    padding?: string;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}
interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}


interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
    organisation_name: string;
}


interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface ProcessedRobotDetails {
    product_name: string;
    robot_name: string;
    robot_serial_number: string;
    robot_mac: string;
    organisation: string;
    site: string;
    site_address: string;
    customer_name: string;
    customer_contact: string;
    active: string;
    purchase_type: string;
    software_version: string;
    remarks: string;
}

const RobotTable: React.FC<RobotTableProps> = ({ actions, type, columns, items }) => {

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' }; // E.g., "Tue"
        const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }; // E.g., "01 Jan 2022"
    
        const day = date.toLocaleDateString('en-GB', dayOptions);
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true }); // E.g., "11:59 PM"
    
        // return `${time}, ${day}, ${formattedDate}`;
        return (
            <>
                <Typography variant="body2" component="span">{time}</Typography>
                <br/>
                <Typography variant="body2" component="span">{day}, {formattedDate}</Typography>
            </>
        );
    };
    

    // const actions = ["Edit"];
    const token = localStorage.getItem('token');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    
    const defaultHeaderStyle = {
        fontSize: "14px",
        color: "grey",
        padding: "15px 50px", 
        fontWeight: "bold",
        backgroundColor: "#F5F9FF",
        whiteSpace: "nowrap"
    };

    // Render function
    const renderHeader = (column: Column) => {
        return {
            ...defaultHeaderStyle,
            padding: column.padding ? column.padding : defaultHeaderStyle.padding
        };
    };

    const content = {
        fontSize:"12px",
        color:"black",
        padding:"15px",
        // backgroundColor:"white"
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); 
    };



    const temp = Object.values(items).map((robot: RobotInfo) => {
        return {
            product_name: robot.generalInfo.robot_name,
            robot_name: robot.generalInfo.robot_name,
            robot_serial_number: robot.generalInfo.robot_serial_number,
            robot_mac: robot.generalInfo.robot_mac,
            organisation: robot.generalInfo.organisation,
            site: "Autove",
            site_address: "10 Hospital Blvd, Singapore 168582",
            customer_name: "Cheryl",
            customer_contact: "9738 3966",
            active: "active",
            purchase_type: "Enterprise",
            software_version: "10.0.0.7",
            remarks: "NA"
        };
    });

    const handleRowClick = (robotId: string) => {
        // console.log("Row clicked, robot ID:", robotId);
        navigate(`/robots/${robotId}`);
    };

    // make it descending
    const rowsToShow = temp && Array.isArray(temp) ? temp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse() : [];
    useEffect(() => {
        console.log("rowsToShow: ", rowsToShow);
    }, [rowsToShow]);
    

    const itemCount = temp && Array.isArray(temp) ? temp.length : 0;


    const renderRow = (info: ProcessedRobotDetails) => {
        return (
            <TableRow 
                key={info.robot_serial_number} 
                sx={{ cursor: 'pointer', backgroundColor:"white", '&:hover': {backgroundColor: '#f5f5f5'} }}
                onClick={() => handleRowClick(info.robot_mac)}
                >
                {columns.map(column => (
                <TableCell key={column.id} align="center" sx={content}>
                    {info[column.id as keyof ProcessedRobotDetails]}
                </TableCell>
            ))}
            </TableRow>
        );
    };
    
    return (
        <Paper elevation={2}  sx={{ display: 'flex', flexDirection: 'column', height: "660px",  borderRadius:"10px", border:"1px solid #E0E0E0", m:'20px auto' }}>
            <TableContainer sx={{ backgroundColor: "#F5F5F5", alignItems: "center", overflowX: "auto" }}>
                <Table stickyHeader>
                    <TableHead sx={{ width: "100%", margin: "auto" }}>
                        <TableRow sx={{ width: "100%", margin: "auto" }}>
                            {columns.map(column => (
                                <TableCell key={column.id} align="center" sx={renderHeader(column)}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsToShow.map(info => renderRow(info))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={itemCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ flexShrink: 0, boxShadow:"none" }} // Prevent pagination from shrinking
            />
        </Paper>
    );
};

 
export default RobotTable;