
import { ListItemText, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { DropdownProps } from './index.types';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

const Dropdown = ({ label, children, sx }: DropdownProps) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)

  const onToggleMenu = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div style={{ position: 'relative' }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',        
        cursor: 'pointer',
        marginRight: '16px',
        ...sx
      }}
        onClick={onToggleMenu}
      >
        <Typography
          variant="h6"
          sx={{
            userSelect: 'none',
            fontSize: "14px",
            '&:hover': {
              cursor: 'pointer',
              color: "#4e4e4e"
            }
          }}
        >
          {label}
        </Typography>
        <div style={{ display: 'flex', alignItems:'center', justifyItems: 'center', transition: 'all', transitionDuration: '300ms', transform: `rotate(${isOpen ? '180' : '0'}deg)` }}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {
        isOpen && (
          <>
            <Paper elevation={3} sx={{ position: 'absolute', top: '32px', zIndex: 150, left: 0 }}>
              <MenuList onClick={() => setIsOpen(false)}>
                {children}
              </MenuList>
            </Paper>
            <div style={{
              position: 'fixed',
              inset: 0,
              backgroundColor: 'rgba(52, 52, 52, 0)',
              cursor: 'pointer',
              zIndex: 149
            }}
              onClick={() => setIsOpen(false)}
            >
            </div>
          </>
        )
      }
    </div>
  );
};

export default Dropdown;
