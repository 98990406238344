export const getJobTypeLabel = (value: string) => {
  switch (value) {
    case 'delivery_job':
      return 'Delivery Job'
      break;

    default:
      break;
  }

  return value
}

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];
