import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import door from '../../../assets/door.png'; 
import elevator from '../../../assets/elevator.png'; 
import { generalStatus } from "../../../utils/DummyData";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface GeneralDetails {
    door: StatusDetails[];
    lift: StatusDetails[];
}

interface StatusDetails {
    name: string;
    status: string;
    connected: boolean;
}

interface GeneralStatusProps {
    handleStatusBoardClose?: () => void;
}

const GeneralStatus: React.FC<GeneralStatusProps> = ({ handleStatusBoardClose }) => {

    // const [tabValue, setTabValue] = useState(0);

    // const handleTabChange = (newValue: number) => {
    //     setTabValue(newValue);
    // }
    const generalInfo: GeneralDetails = generalStatus;

    // const filteredJobs = tabValue === 0 ? generalInfo.door : generalInfo.lift
    // const selectedImage = tabValue === 0 ? door : elevator;
    return ( 
        <Stack
            sx={{
                position: "absolute",
                // top: "225px",
                top:  "235px" ,
                right: "30px",
                width: "400px",
                bgcolor: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                height: "450px",
            }}>
                <>
                    <Box sx={{  height: "150px", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m:1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m:1 }}>
                                <Typography sx={{ color: "#000", ml:1, fontWeight:"600", fontSize:"16px", mt:0.2 }}>
                                    Lift Status
                                </Typography>
                                <IconButton onClick={handleStatusBoardClose}>
                                    <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                                </IconButton>
                            </Box>
                            
                            {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%",}}>
                                <Tabs 
                                    value={tabValue} 
                                    onChange={(event, newValue) => handleTabChange(newValue)}
                                    sx={{
                                        ml:2,
                                        fontSize: "8px",
                                        '.MuiTabs-indicator': {
                                            display: 'none',  
                                        },
                                        '& .MuiTab-root': {
                                            color: '#A0A0A0', 
                                            fontSize: '12px',
                                            height: '20px', 
                                            minHeight: '20px',
                                            '&.Mui-selected': {
                                                color: '#087D46', 
                                                backgroundColor: '#00E58E',
                                                borderRadius: '30px',
                                            }
                                        }
                                }}>
                                    <Tab label="Door" />
                                    <Tab label="Lift" />
                                </Tabs>
                            </Box> */}
                    </Box>
                </Box>
                <Box>
                    <List sx={{overflowY: "auto", overflowX: "hidden", mt:-12}}>
                        {generalInfo['lift'].map((job, index) => (
                            <>
                                <ListItem key={index} sx={{ py: 1, paddingLeft: "35px"}}>

                                    <Stack sx={{width: "60px", height:"60px", bgcolor: "#f5f5f5", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center",mr:4 }}>
                                        <img src={elevator} style={{ width: "40px", height: "40px" }} />
                                    </Stack>
                                    <ListItemText>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                                            <Typography sx={{ color: "#000", fontSize:"16px", fontWeight: "500" }}>
                                                {job.name}
                                            </Typography>
                                            <Typography sx={{ color: "#727272", fontSize:"12px" }}>
                                                Status: 
                                                <span style={{ color: "#727272", marginLeft:"5px" }}>
                                                    {job.status}
                                                </span>
                                            </Typography>
                                            <Typography sx={{ color: "#727272", fontSize:"12px" }}>
                                                Connected: 
                                                <span style={{ color: job.connected ? "#12B76A" : "#FF0000", marginLeft:"5px" }}>
                                                    {job.connected ? "Online" : "Offline"}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                                {index < generalInfo.lift.length - 1 && <Divider sx={{ width: "85%", margin:"auto", }} />}
                            </>
                        ))}
                    </List>
                </Box>
                </>
            
        </Stack>
     );
}
 
export default GeneralStatus;