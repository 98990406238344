import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Fuse from "fuse.js";
import { Box, Typography, Button, Grid, TextField, InputAdornment, Popover, Alert, Chip, Dialog, DialogContentText, Tab, Tabs} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTab from './users/UserTab';
import FeatureTab from './features/FeatureTab';
interface columnType {
    id: string;
    label: string;
    padding?: string;
}

interface UserData {
    username: string;
    email: string;
    role: string;
    default_location: string;
    feature: FeatureData;
}

interface FeatureData {
    RobotMonitoring: {
        ViewRobotLocationsOnMap: boolean;
        ViewRobotStatuses: boolean;
        ViewNotificationAlerts: boolean;
        ViewCriticalAlerts: boolean;
    };
    JobManagement: {
        CreateNewJob: boolean;
        ViewAllJob: boolean;
        CreateScheduledJob: boolean;
        CancelScheduledJob: boolean;
        ViewJobsCreatedbyUser: boolean;
        UpdateJobsOwn: boolean;
        UpdateJobsAll: boolean;
        CancelJobsOwn: boolean;
        CancelJobsAll: boolean;
    };
    NotificationManagement: {
        ReceiveGeneralJobNotificationSelf: boolean;
        ReceiveNotificationAtDeliveryLocationAsRecipient: boolean;
        ReceiveNotificationAtPickUpLocationAsSender: boolean;
        ReceiveCriticalSystemNotifications: boolean;
        ManageNotificationPreferences: boolean;
    };
    ReportLogs: {
        GenerateAllJobReports: boolean;
        ViewRobotLogs: boolean;
    };
    UserManagement: {
        ViewAllUsers: boolean;
        AddOrRemoveUsers: boolean;
        AssignRoles: boolean;
    };
    UserProfileManagement: {
        ConfigureUserProfileSelf: boolean;
        AssignUserProfileLocation: boolean;
    };
    MapManagement: {
        ConfigureRobotMapLayout: boolean;
        AddOrRemoveMarkersOnMap: boolean;
        ManageMapLayoutAndRobotMap: boolean;
        AddOrRemoveRobotsFromMap: boolean;
    };
    RobotManagement: {
        ViewRobotInformation: boolean;
        AddOrRemoveRobots: boolean;
        UpdateRobotInformation: boolean;
    };
}
interface FeatureGrouping {
    name: string;
    features: FeatureData;
}

const RoleOverview = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const userColumns: columnType[] = [
        { id: 'username', label: 'Username', padding: "15px 20px" },
        { id: 'email', label: 'Email', padding: "15px 20px" },
        { id: 'role', label: 'Role', padding: "15px 20px" },
        { id: 'default_location', label: 'Default Location', padding: "15px 20px" },
        { id: 'feature', label: 'Feature', padding: "15px 20px" },
    ];
    const featureColumns: columnType[] = [
        { id: 'name', label: 'Role', padding: "15px 20px" },
        { id: 'RobotMonitoring', label: 'Robot Monitoring', padding: "15px 20px" },
        { id: 'JobManagement', label: 'Job Management', padding: "15px 20px" },
        { id: 'NotificationManagement', label: 'Notification Management', padding: "15px 20px" },
        { id: 'ReportLogs', label: 'Report and Logs', padding: "15px 20px" },
        { id: 'UserManagement', label: 'User Management', padding: "15px 20px" },
        { id: 'UserProfileManagement', label: 'User Profile Management', padding: "15px 20px" },
        { id: 'MapManagement', label: 'Map Management', padding: "15px 20px" },
        { id: 'RobotManagement', label: 'Robot Management', padding: "15px 20px" },
    ];
    

    const dummyFeatureGrouping: FeatureGrouping[] = [
        {
            name: 'Super Admin',
            features: {
                RobotMonitoring: {
                    ViewRobotLocationsOnMap: true,
                    ViewRobotStatuses: true,
                    ViewNotificationAlerts: true,
                    ViewCriticalAlerts: true,
                },
                JobManagement: {
                    CreateNewJob: true,
                    ViewAllJob: true,
                    CreateScheduledJob: true,
                    CancelScheduledJob: true,
                    ViewJobsCreatedbyUser: true,
                    UpdateJobsOwn: true,
                    UpdateJobsAll: true,
                    CancelJobsOwn: true,
                    CancelJobsAll: true,
                },
                NotificationManagement: {
                    ReceiveGeneralJobNotificationSelf: true,
                    ReceiveNotificationAtDeliveryLocationAsRecipient: true,
                    ReceiveNotificationAtPickUpLocationAsSender: true,
                    ReceiveCriticalSystemNotifications: true,
                    ManageNotificationPreferences: true,
                },
                ReportLogs: {
                    GenerateAllJobReports: true,
                    ViewRobotLogs: true,
                },
                UserManagement: {
                    ViewAllUsers: true,
                    AddOrRemoveUsers: true,
                    AssignRoles: true,
                },
                UserProfileManagement: {
                    ConfigureUserProfileSelf: true,
                    AssignUserProfileLocation: true,
                },
                MapManagement: {
                    ConfigureRobotMapLayout: true,
                    AddOrRemoveMarkersOnMap: true,
                    ManageMapLayoutAndRobotMap: true,
                    AddOrRemoveRobotsFromMap: true,
                },
                RobotManagement: {
                    ViewRobotInformation: true,
                    AddOrRemoveRobots: true,
                    UpdateRobotInformation: true,
                },
            }
        },
        {
            name: 'Admin',
            features: {
                RobotMonitoring: {
                    ViewRobotLocationsOnMap: true,
                    ViewRobotStatuses: true,
                    ViewNotificationAlerts: true,
                    ViewCriticalAlerts: true,
                },
                JobManagement: {
                    CreateNewJob: true,
                    ViewAllJob: true,
                    CreateScheduledJob: true,
                    CancelScheduledJob: true,
                    ViewJobsCreatedbyUser: true,
                    UpdateJobsOwn: true,
                    UpdateJobsAll: true,
                    CancelJobsOwn: true,
                    CancelJobsAll: true,
                },
                NotificationManagement: {
                    ReceiveGeneralJobNotificationSelf: true,
                    ReceiveNotificationAtDeliveryLocationAsRecipient: true,
                    ReceiveNotificationAtPickUpLocationAsSender: true,
                    ReceiveCriticalSystemNotifications: true,
                    ManageNotificationPreferences: true,
                },
                ReportLogs: {
                    GenerateAllJobReports: true,
                    ViewRobotLogs: true,
                },
                UserManagement: {
                    ViewAllUsers: true,
                    AddOrRemoveUsers: true,
                    AssignRoles: true,
                },
                UserProfileManagement: {
                    ConfigureUserProfileSelf: true,
                    AssignUserProfileLocation: true,
                },
                MapManagement: {
                    ConfigureRobotMapLayout: true,
                    AddOrRemoveMarkersOnMap: true,
                    ManageMapLayoutAndRobotMap: true,
                    AddOrRemoveRobotsFromMap: true,
                },
                RobotManagement: {
                    ViewRobotInformation: true,
                    AddOrRemoveRobots: true,
                    UpdateRobotInformation: true,
                },
            }
        },
    ]
    const dummyUserData: UserData[] = [
        {
            username: 'Kelvin Khoo',
            email: 'kelvin@autove.ai',
            role: 'Super Admin',
            default_location: 'Discharge Pharmacy',
            feature: {
                RobotMonitoring: {
                    ViewRobotLocationsOnMap: true,
                    ViewRobotStatuses: true,
                    ViewNotificationAlerts: true,
                    ViewCriticalAlerts: true,
                },
                JobManagement: {
                    CreateNewJob: true,
                    ViewAllJob: true,
                    CreateScheduledJob: true,
                    CancelScheduledJob: true,
                    ViewJobsCreatedbyUser: true,
                    UpdateJobsOwn: true,
                    UpdateJobsAll: true,
                    CancelJobsOwn: true,
                    CancelJobsAll: true,
                },
                NotificationManagement: {
                    ReceiveGeneralJobNotificationSelf: true,
                    ReceiveNotificationAtDeliveryLocationAsRecipient: true,
                    ReceiveNotificationAtPickUpLocationAsSender: true,
                    ReceiveCriticalSystemNotifications: true,
                    ManageNotificationPreferences: true,
                },
                ReportLogs: {
                    GenerateAllJobReports: true,
                    ViewRobotLogs: true,
                },
                UserManagement: {
                    ViewAllUsers: true,
                    AddOrRemoveUsers: true,
                    AssignRoles: true,
                },
                UserProfileManagement: {
                    ConfigureUserProfileSelf: true,
                    AssignUserProfileLocation: true,
                },
                MapManagement: {
                    ConfigureRobotMapLayout: true,
                    AddOrRemoveMarkersOnMap: true,
                    ManageMapLayoutAndRobotMap: true,
                    AddOrRemoveRobotsFromMap: true,
                },
                RobotManagement: {
                    ViewRobotInformation: true,
                    AddOrRemoveRobots: true,
                    UpdateRobotInformation: true,
                },
            }
        },
    ]
    
        
    return ( 
        <Box sx={{ maxWidth: "95%", m: "15px auto", height: "100%",}}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px", color: "#000000",  mt:1}}>
                Access Control
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt:2, mb:2}}>
            <Tabs value={tabValue} onChange={handleChange}
            sx={{
                ml:-2,
                mt:2,
                // mb:2,
                fontSize: "12px",
                '.MuiTabs-indicator': {
                    display: 'none',  
                },
                '& .MuiTab-root': {
                    color: '#A0A0A0', 
                    fontSize: '12px',
                    height: '20px', 
                    minHeight: '20px',
                    '&.Mui-selected': {
                        color: '#378FFE', 
                        // backgroundColor: 'transparent',
                        borderRadius: '30px',
                    }
                }
            }}
            >
                <Tab label="Members" />
                <Tab label="Feature Grouping" />
            </Tabs>
            <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => navigate("/access-control/new-grouping")}>
                    {tabValue === 0 ? 'Add User' : 'Add Grouping'}
                </Button>
            </Box>
            </Box>

            {tabValue === 0 && (
                <UserTab items={dummyUserData} columns={userColumns} actions = {['edit']} />
            )}
            {tabValue === 1 && (
                <FeatureTab items={dummyFeatureGrouping} columns={featureColumns} actions = {['edit']} />
            )}
                
        </Box>
    );
}
 
export default RoleOverview;