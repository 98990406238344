import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
interface UserTabProps {
    actions?: string[];
    columns: ColumnType[];
    items: UserData[];
}

interface ColumnType {
    id: string;
    label: string;
    padding?: string;
}

interface UserData {
    username: string;
    email: string;
    role: string;
    default_location: string;
    feature: FeatureData;
}

interface FeatureData {
    RobotMonitoring: {
        ViewRobotLocationsOnMap: boolean;
        ViewRobotStatuses: boolean;
        ViewNotificationAlerts: boolean;
        ViewCriticalAlerts: boolean;
    };
    JobManagement: {
        CreateNewJob: boolean;
        ViewAllJob: boolean;
        CreateScheduledJob: boolean;
        CancelScheduledJob: boolean;
        ViewJobsCreatedbyUser: boolean;
        UpdateJobsOwn: boolean;
        UpdateJobsAll: boolean;
        CancelJobsOwn: boolean;
        CancelJobsAll: boolean;
    };
    NotificationManagement: {
        ReceiveGeneralJobNotificationSelf: boolean;
        ReceiveNotificationAtDeliveryLocationAsRecipient: boolean;
        ReceiveNotificationAtPickUpLocationAsSender: boolean;
        ReceiveCriticalSystemNotifications: boolean;
        ManageNotificationPreferences: boolean;
    };
    ReportLogs: {
        GenerateAllJobReports: boolean;
        ViewRobotLogs: boolean;
    };
    UserManagement: {
        ViewAllUsers: boolean;
        AddOrRemoveUsers: boolean;
        AssignRoles: boolean;
    };
    UserProfileManagement: {
        ConfigureUserProfileSelf: boolean;
        AssignUserProfileLocation: boolean;
    };
    MapManagement: {
        ConfigureRobotMapLayout: boolean;
        AddOrRemoveMarkersOnMap: boolean;
        ManageMapLayoutAndRobotMap: boolean;
        AddOrRemoveRobotsFromMap: boolean;
    };
    RobotManagement: {
        ViewRobotInformation: boolean;
        AddOrRemoveRobots: boolean;
        UpdateRobotInformation: boolean;
    };
}
interface FeatureGrouping {
    name: string;
    features: FeatureData;
}
const UserTab: React.FC<UserTabProps> = ({ items, columns, actions }) => {

    const renderFeatureValue = (featureData: FeatureData): React.ReactNode => {
        return (
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center", // Ensures content is centered
                gap: 0.5, // Adds space between boxes
                p: 1, // Padding inside the outer box
            }}>
                {Object.entries(featureData).map(([key, subFeatures], index) => {
                    const allTrue = Object.values(subFeatures).every(value => value);
                    const featureName = key.replace(/([a-z])([A-Z])/g, '$1 $2'); // Adds space before each capital letter for readability
                    return (
                        <Box key={index} sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Center align items within the box
                            width: 'auto', // Auto width based on content
                            m: 0.5,
                            border: `1px solid ${allTrue ? '#378FFE' : '#FFA800'}`,
                            borderRadius: "5px",
                            p: "3px 10px", // Reduced padding for a more compact look
                            backgroundColor: allTrue ? '#E8F4FF' : '#FFF4E5' // Light background color for distinction
                        }}>
                            <Typography sx={{
                                fontSize: "13px",
                                color: allTrue ? '#378FFE' : '#FFA800',
                                fontWeight: 'bold',
                                textAlign: 'center', // Ensures text is centered
                                width: '100%' // Ensures the text takes full width of the box
                            }}>
                                {/* {featureName} {allTrue ? '(Full Access)' : '(Partial)'} */}
                                {featureName}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        );
    };
    
    
    
    const navigate = useNavigate();
    return ( 
        <Box>
            <TableContainer component={Paper} sx={{ overflow: "auto", maxHeight: "680px" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" sx={{ padding: column.padding, backgroundColor: "#f2f2f2" }}>
                                    <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        {column.label}
                                    </Typography>
                                </TableCell>
                            ))}
                            {actions && <TableCell align="center" sx={{ backgroundColor: "#f2f2f2" }}>
                                <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Actions</Typography>
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="center" sx={{ padding: "15px", fontSize: "" }}>{item.username}</TableCell>
                                <TableCell align="center" sx={{ padding: "15px", fontSize: "13px" }}>{item.email}</TableCell>
                                <TableCell align="center" sx={{ padding: "15px", fontSize: "13px" }}>{item.role}</TableCell>
                                <TableCell align="center" sx={{ padding: "15px", fontSize: "13px" }}>{item.default_location}</TableCell>
                                <TableCell align="center" sx={{
                                    padding: "15px",
                                    fontSize: "13px",
                                    maxWidth: "250px", // Set a maximum width for the feature cell
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                    {renderFeatureValue(item.feature)}
                                </TableCell>
                                {actions && (   
                                    <TableCell align="center" sx={{ padding: "15px" }}>
                                        <Button variant="contained" color="primary" size="small" onClick={() => navigate(`/access-control/edit-user/${item.username}`)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => navigate("/access-control/new-user")}>
                    Add User
                </Button>
            </Box> */}
        </Box>
    );
}

export default UserTab;
