import React, { useEffect, useState } from 'react';
import { Box, Typography, AppBar, Toolbar, Icon , IconButton, Badge, Menu, MenuItem, ListItemText} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomAvatar from '../CustomAvatar';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { toggle, setTrue, setFalse } from '../../store/deliveryDashboardSlice';
import { useWebSocket } from '../useWebSocket';
import GroupsIcon from '@mui/icons-material/Groups';
import { setNotificationHistoryPanel } from '../../store/notification/notificationSlice';
import NotificationHistoryPanel from '../../routes/dashboard/components/NotificationHistoryPanel';
import { useDispatch, useSelector } from 'react-redux';
import { JobDetails2, JobData } from '../../store/types';
import Dropdown from '../dropdown';
import autove from '../../assets/autove.jpeg';
import { setIsSelectedRobot } from '../../store/robot/robotSlice';

interface TopBarProps {
    handleIsOpen: () => void;
    handleNotificationDisplay: () => void;
    notificationCount: number;
    toggleSidebar: () => void;
}

type RouteMenuType = {
  route?: string, 
  label: string,
  pageName : string, 
  children?: {
    route?: string, 
    label: string,
    pageName : string, 
  }[]
}

const TopBar: React.FC<TopBarProps> = ({  handleIsOpen, handleNotificationDisplay, notificationCount, toggleSidebar }) => {
    const isOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState("");
    const [profile, setProfile] = useState<{ username: string } | null>(null);
    const location = useLocation();
    const [isDashboard, setIsDashboard] = useState(false);
    const dispatch = useDispatch();
    const dummyNotificationHistory: JobData = {};
    const [currentPage, setCurrentPage] = useState<string>('');

    useEffect(() => {
        console.log("location", location);
        if (location.pathname === '/') {
            setCurrentPage('Dashboard');
            setIsDashboard(true);
        }
        else {
            console.log("location 2", location.pathname.split('/')[1]);

            setCurrentPage(location.pathname.split('/')[1]);
            setIsDashboard(false);
        }
        console.log("isDashboard", isDashboard);
    }, [location]);

    useEffect(() => {
        // Load profile data from localStorage
        const loadProfile = () => {
            const storedProfile = localStorage.getItem('profile');
            if (storedProfile) {
                setProfile(JSON.parse(storedProfile));
            }
        };

        loadProfile();

        // Update current time every second
        const updateCurrentTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            const seconds = now.getSeconds().toString().padStart(2, "0");
            setCurrentTime(`${hours}:${minutes}:${seconds}`);
        };

        updateCurrentTime();
        const intervalId = setInterval(updateCurrentTime, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const notificationHistoryPanel = useSelector ((state: any) => state.notification.notificationHistoryPanel);
    
    const handleSelectedJob2 = (job: JobDetails2) => {
        console.log("Selected Job:", job)
    
    }

    const handleDeleteNotification = (notificationId: string) => {
        console.log("Notification ID:", notificationId);
        // dispatch(deleteNotification(notificationId));
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };
    const handleNavigation = (route: string) => {
        navigate(route);
    };

    const handleDeliveryClick = () => {
        isOpen ? dispatch(setFalse()) : dispatch(setTrue());
        if (location.pathname !== '/') {
            navigate('/');
        }
    }
    const [profileLoaded, setProfileLoaded] = useState(false);
    const getProfile = JSON.parse(localStorage.getItem('profile') || '{}');
    useEffect(() => {
        if (profile !== null && profile !== undefined) {
            setProfileLoaded(true);
          }
    }, [getProfile]);

    const [userName, setUserName] = useState<string>('');
    const [initials, setInitials] = useState<string>('');
    useEffect(() => {
        if (profileLoaded && getProfile) {
            setUserName(getProfile.username || '');
            setInitials(getProfile.username[0] || '');
        }
    }, [profileLoaded]);

    const routes:RouteMenuType[] = [
        { route: '', label: 'Applications', pageName : 'application', 
          children: [
            { route: '/delivery', label: 'Delivery', pageName : 'delivery' },
          ]
        },
        { route: '/access-control', label: 'Users', pageName : 'access-control' },
        { route: '/robots', label: 'Robots' , pageName : 'robots'},
        { route: '/jobs', label: 'Jobs' , pageName : 'jobs'},
        { route: '/reports', label: 'Reports' , pageName : 'reports'},
        { route: '/scheduled-job', label: 'Scheduled Job' , pageName : 'scheduled-job'},
        // { label: 'Settings' , pageName : 'setting',
        //   children: [
        //     { route: '/users', label: 'User Management' , pageName : 'user-management'},
        //     { route: '/map', label: 'Map Config' , pageName : 'map-config'},
        //   ]
        // },
    ];

    const isCurrentChildRoute = (route: RouteMenuType) => {
      if (!route.children) {
        return false
      }
      return route.children.map((item) => item.route).includes(location.pathname)
    }

    return (
        <AppBar position="sticky" sx={{
            bgcolor: "white",
            boxShadow: "none",
            background: location.pathname === '/' ? "#DAFBEF" : "linear-gradient(to bottom, #DAFBEF, #F8F7F7)",
        }}>
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '98%', margin: "auto" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <IconButton onClick={toggleSidebar} sx={{ mr:4 }}>

                            <MenuIcon sx={{ color: "#444444", fontSize: "25px",}} />
                        </IconButton> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', ml:1, '&:hover': { cursor: 'pointer'}}} onClick={() => {dispatch(setFalse()); dispatch(setIsSelectedRobot(false)); navigate('/')}}>
                            <img src={autove} alt="logo" style={{ width: '30px', height: '30px', borderRadius: '8px',  }} />
                            <Typography variant="h6" sx={{ color: "#000", mr: 4, fontSize: "24px", }}>
                            RoboHub
                        </Typography>
                        </Box>
                        {/* <Typography variant="h6" sx={{ color: "#444444", mr: 4, fontSize: "24px", '&:hover': { cursor: 'pointer',}}} onClick={() => navigate('/')}>
                            RoboHub
                        </Typography> */}

                        {routes.map((route, index) => route.children && route.children.length > 0 ?(
                          <Dropdown key={index} label={route.label} sx={{ color: isCurrentChildRoute(route) ? "#000" : "#A7A7A7"}}>
                            { route.children.map((subMenu, idx) => (
                              <MenuItem key={idx} onClick={() => subMenu.route === '/delivery' ? handleDeliveryClick() : subMenu.route && navigate(subMenu.route)}>
                                <ListItemText>{subMenu.label}</ListItemText>
                              </MenuItem>
                            ))}
                          </Dropdown>
                        ): (
                          <Typography key={index} variant="h6" sx={{ color: location.pathname === route.route ? "#000" : "#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => route.route && navigate(route.route)}>
                            {route.label}
                          </Typography>
                        ))}

                    </Box>
                        
                        
                    {/* </Box> */}

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ mr: 2, color: "#444444" }}>
                            {currentTime}
                        </Typography>
                        <IconButton onClick={()=> dispatch(setNotificationHistoryPanel(true))} sx={{ mr: 2 }}>
                        <Badge badgeContent={notificationCount} color="primary">
                            <NotificationsNoneIcon sx={{ color: "#444444" }} />
                        </Badge>
                        </IconButton>
                        {/* <SettingsIcon sx={{ color: "#444444", ml: 2, mr: 2 }} /> */}
                        {profileLoaded && initials!="" && userName!="" &&(
                            <>
                                <CustomAvatar initials={initials} />
                                <Typography variant="h6" sx={{ color: "#444444", ml: 2 }}>
                                    {userName}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Toolbar>
            <NotificationHistoryPanel open={notificationHistoryPanel} dummyNotificationHistory={{}} handleSelectJob={handleSelectedJob2} handleDeleteNotification={handleDeleteNotification} />
        </AppBar>
    );
}

export default TopBar;
