import { useRequest } from "../../utils/api"
import { RequestConfigType } from "../../utils/api.type"
import { RobotResponseType } from "./index.type"

const GET_ROBOT = '/v1/rms/robot/'
interface RobotListProps extends RequestConfigType {
  pageSize?: number
}
export const useRobots = (obj = {} as RobotListProps) => {
  return useRequest<RobotResponseType>(GET_ROBOT, {
    ...obj,
    pageSize: obj.pageSize || 20
  }, {
    enabled: obj.enabled
  })
}