import { ElevatorRounded } from "@mui/icons-material";
import { CSSProperties } from "react";

export default function LiftIcon({ style }: { style?: CSSProperties }) {
  return (
    <ElevatorRounded
      style={{
        backgroundColor: "#1565C0",
        borderRadius: "4px",
        color: "#FFF",
        fontSize: '40px',
        ...style
      }}
    />
  )
}