import React, { useRef, useState } from 'react';
import { Box, Button, Typography, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableBody, TableRow, TableCell, TableContainer, TextField, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface FeatureData {
    RobotMonitoring: {
        ViewRobotLocationsOnMap: boolean;
        ViewRobotStatuses: boolean;
        ViewNotificationAlerts: boolean;
        ViewCriticalAlerts: boolean;
    };
    JobManagement: {
        CreateNewJob: boolean;
        ViewAllJob: boolean;
        CreateScheduledJob: boolean;
        CancelScheduledJob: boolean;
        ViewJobsCreatedbyUser: boolean;
        UpdateJobsOwn: boolean;
        UpdateJobsAll: boolean;
        CancelJobsOwn: boolean;
        CancelJobsAll: boolean;
    };
    NotificationManagement: {
        ReceiveGeneralJobNotificationSelf: boolean;
        ReceiveNotificationAtDeliveryLocationAsRecipient: boolean;
        ReceiveNotificationAtPickUpLocationAsSender: boolean;
        ReceiveCriticalSystemNotifications: boolean;
        ManageNotificationPreferences: boolean;
    };
    ReportLogs: {
        GenerateAllJobReports: boolean;
        ViewRobotLogs: boolean;
    };
    UserManagement: {
        ViewAllUsers: boolean;
        AddOrRemoveUsers: boolean;
        AssignRoles: boolean;
    };
    UserProfileManagement: {
        ConfigureUserProfileSelf: boolean;
        AssignUserProfileLocation: boolean;
    };
    MapManagement: {
        ConfigureRobotMapLayout: boolean;
        AddOrRemoveMarkersOnMap: boolean;
        ManageMapLayoutAndRobotMap: boolean;
        AddOrRemoveRobotsFromMap: boolean;
    };
    RobotManagement: {
        ViewRobotInformation: boolean;
        AddOrRemoveRobots: boolean;
        UpdateRobotInformation: boolean;
    };
}
interface FeatureGrouping {
    name: string;
    features: FeatureData;
}

const AddFeatures = () => {
    const navigate = useNavigate();
    const paperRef = useRef<HTMLDivElement>(null);
    const [featureGrouping, setFeatureGrouping] = useState<FeatureGrouping>({
        name: "",
        features: {
            RobotMonitoring: {
                ViewRobotLocationsOnMap: false,
                ViewRobotStatuses: false,
                ViewNotificationAlerts: false,
                ViewCriticalAlerts: false,
            },
            JobManagement: {
                CreateNewJob: false,
                ViewAllJob: false,
                CreateScheduledJob: false,
                CancelScheduledJob: false,
                ViewJobsCreatedbyUser: false,
                UpdateJobsOwn: false,
                UpdateJobsAll: false,
                CancelJobsOwn: false,
                CancelJobsAll: false,
            },
            NotificationManagement: {
                ReceiveGeneralJobNotificationSelf: false,
                ReceiveNotificationAtDeliveryLocationAsRecipient: false,
                ReceiveNotificationAtPickUpLocationAsSender: false,
                ReceiveCriticalSystemNotifications: false,
                ManageNotificationPreferences: false,
            },
            ReportLogs: {
                GenerateAllJobReports: false,
                ViewRobotLogs: false,
            },
            UserManagement: {
                ViewAllUsers: false,
                AddOrRemoveUsers: false,
                AssignRoles: false,
            },
            UserProfileManagement: {
                ConfigureUserProfileSelf: false,
                AssignUserProfileLocation: false,
            },
            MapManagement: {
                ConfigureRobotMapLayout: false,
                AddOrRemoveMarkersOnMap: false,
                ManageMapLayoutAndRobotMap: false,
                AddOrRemoveRobotsFromMap: false,
            },
            RobotManagement: {
                ViewRobotInformation: false,
                AddOrRemoveRobots: false,
                UpdateRobotInformation: false,
            },
        }
    });



    const handleSelectAll = (featureGroup: keyof FeatureData, selectAll: boolean) => {
        setFeatureGrouping(prevState => ({
            ...prevState,
            features: {
                ...prevState.features,
                [featureGroup]: Object.keys(prevState.features[featureGroup]).reduce((acc, key) => ({
                    ...acc,
                    [key]: selectAll
                }), {})
            }
        }));
    };

const handleCheckboxChange = <T extends keyof FeatureData>(
    featureGroup: T,
    featureName: keyof FeatureData[T]  // Assuring TypeScript of the integrity of featureName
) => {
    setFeatureGrouping(prevState => ({
        ...prevState,
        features: {
            ...prevState.features,
            [featureGroup]: {
                ...prevState.features[featureGroup],
                [featureName]: !prevState.features[featureGroup][featureName]
            }
        }
    }));
};

const renderFeatureGroup = <T extends keyof FeatureData>(featureGroup: T) => {
    const featureGroupData = featureGrouping.features[featureGroup];
    const allSelected = Object.values(featureGroupData).every(value => value);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${featureGroup}-content`} id={`${featureGroup}-header`}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "14px" }}>
                    {camelToSpace(featureGroup)}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <FormControlLabel
                        sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px",mt:-2, '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                        control={<Checkbox checked={allSelected} onChange={() => handleSelectAll(featureGroup, !allSelected)} indeterminate={!allSelected && Object.values(featureGroupData).some(value => value)} size='small' />}
                        label="Select All"
                    />
                    {Object.entries(featureGroupData).map(([featureKey, featureValue]) => (
                        <FormControlLabel
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "12px",
                                width: "50%",
                                ml: 3,  // Add margin-left to indent
                                '& .MuiFormControlLabel-label': { fontSize: '14px' } 
                            }}
                            key={featureKey}
                            control={
                                <Checkbox
                                    size='small'
                                    checked={featureValue}
                                    onChange={() => handleCheckboxChange(featureGroup, featureKey as keyof FeatureData[T])}
                                    name={featureKey}
                                />
                            }
                            label={camelToSpace(featureKey)}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

    const camelToSpace = (str: string) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    }


    return (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: "#F7F7F7", alignItems: "center" }}>
            <Paper ref={paperRef} elevation={5} sx={{ width: "80%", margin: "30px auto", display: 'flex', flexDirection: 'column', borderRadius: "5px", overflow: "auto" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', ml: 5, mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb:1 }}>
                        <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "24px" }}>
                            Add Feature Group
                        </Typography>
                        <IconButton onClick={() => navigate("/access-control")} sx={{ mt: -1, mr:2 }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "12px", mb: 1 }}>
                        Please assign the fields accordingly and click on add to add a group
                    </Typography>
                </Box>

                <TableContainer
                    sx={{
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        width: "90%",
                        ml: 5,
                    }}
                >
                    <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        padding: '8px',
                                        width: "30%",
                                        borderRight: "1px solid #E0E0E0",
                                    }}>
                                    Role
                                </TableCell>
                                <TableCell align="left" sx={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "12px", padding: '8px' }}>
                                    <TextField
                                        name="name"
                                        value={featureGrouping.name}
                                        onChange={(e) => setFeatureGrouping({ ...featureGrouping, name: e.target.value })}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            border: "none",
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                            },
                                            "& fieldset": { border: 'none' },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {Object.keys(featureGrouping.features).map(featureGroup => renderFeatureGroup(featureGroup as keyof FeatureData))} */}
                <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", mt: 4, ml: 6 }}>
                    Feature Permissions
                </Typography>

                <TableContainer
                    sx={{
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        width: "90%",
                        mt: 2,
                        ml: 6,
                        mb: 4,
                    }}
                >
                    <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                        <TableBody>
                            {Object.keys(featureGrouping.features).map(featureGroup => renderFeatureGroup(featureGroup as keyof FeatureData))}
                        </TableBody>
                    </Table>
                </TableContainer>

                    <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                console.log('Feature Grouping:', featureGrouping);
                            }}
                        >
                            Save Feature Group
                        </Button>
                    </Box>
            </Paper>
        </div>
    );
};

export default AddFeatures;
