import { GridColDef } from "@mui/x-data-grid";
import { CalendarContentProps } from "./Calendar.types";
import { getJobTypeLabel } from "../_function/common";
import dayjs from "dayjs";

export const columns: GridColDef<CalendarContentProps>[] = [
  { 
    field: 'id',
    headerName: 'NO',
  },
  {
    field: 'title',
    headerName: 'Task Name',
    // width: 150,
  },
  {
    field: 'Task',
    headerName: 'Task Type',
    width: 180,
    valueGetter: (value, row) => getJobTypeLabel(row.job_type || '-') 
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 110,
    valueGetter: (value, row) => dayjs.unix(row.timestamp).format("DD MMM YYYY") 
  },
  {
    field: 'time',
    headerName: 'Time',
    valueGetter: (value, row) => dayjs.unix(row.timestamp).format("HH:mm") 
  },
  {
    field: 'repeat_type',
    headerName: 'Repeat',
    width: 160,
    valueGetter: (value, row) => row.recurring_type,
  },
  {
    field: 'robot',
    headerName: 'Robot',
    width: 180,
    valueGetter: (value, row) => "Call the Nearest Robot",
  },
  {
    field: 'sender_location',
    headerName: 'Sender Location',
    width: 160,
    valueGetter: (value, row) => row.sender_location,
  },
  {
    field: 'recipient_location',
    headerName: 'Recipient Location',
    width: 160,
    valueGetter: (value, row) => row.recipient_location,
  },
];